import React, { Component } from 'react';
import { MDBDataTable } from "mdbreact";

class TopUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: "Stamps"
        };
    };

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });
    };

    render() {

        let data = {
            columns: [],
            rows: []
        }

        if (this.state.filter === "Stamps") {

            data.columns = [
                {
                    label: "Top Users",
                    field: "topUsers",
                    sort: "asc"
                },
                {
                    label: "User name",
                    field: "username",
                    sort: "asc"
                },
                {
                    label: "Number",
                    field: "number",
                    sort: "asc"
                },
                {
                    label: "Stamps",
                    field: "stamps",
                    sort: "asc"
                }
            ]

            let ranking = [];

            for(let i = 0; i < this.props.userData.length; i++){
                const stamp = this.props.transactionData.filter(data => data.payment.paymentAmount >= 5 && data.memberId === this.props.userData[i].memberId).length;
                ranking.push([this.props.userData[i].memberId, this.props.userData[i].name, this.props.userData[i].number, stamp]);
            }

            ranking.sort(function (a, b) {
                return b[3] - a[3];
            });

            for (let i = 0; i < 5; i++) {
                if (ranking[i]) {
                    const dataObject = {
                        topUsers: ranking[i][0],
                        username: ranking[i][1],
                        number: ranking[i][2],
                        stamps: ranking[i][3]
                    };
                    data.rows.push(dataObject);
                }
            }

        } else if (this.state.filter === "Spending") {

            data.columns = [
                {
                    label: "Top Users",
                    field: "topUsers",
                    sort: "asc"
                },
                {
                    label: "User name",
                    field: "username",
                    sort: "asc"
                },
                {
                    label: "Number",
                    field: "number",
                    sort: "asc"
                },
                {
                    label: "Spending",
                    field: "spending",
                    sort: "asc"
                }
            ]

            let ranking = [];

            for(let i = 0; i < this.props.userData.length; i++){
                const transaction = this.props.transactionData.filter(data => data.memberId === this.props.userData[i].memberId);
                const transactionAmount = transaction.map(transaction => transaction.payment.paymentAmount);
                const spending = transactionAmount.reduce((a, b) => a + b, 0);
                ranking.push([this.props.userData[i].memberId, this.props.userData[i].name, this.props.userData[i].number, spending]);
            }

            ranking.sort(function (a, b) {
                return b[3] - a[3];
            });

            for (let i = 0; i < 5; i++) {
                if (ranking[i]) {
                    const dataObject = {
                        topUsers: ranking[i][0],
                        username: ranking[i][1],
                        number: ranking[i][2],
                        spending: ranking[i][3]
                    };
                    data.rows.push(dataObject);
                }
            }
        }


        return (
            <div>
                <div>
                    <h6 className="text-center">Top Users</h6>
                    <div>
                        <label htmlFor="filter">Sort by: </label>
                        <select id="filter" name="filter" className="browser-default form-select" value={this.state.filter} onChange={this.handleChange}>
                            <option value="Stamps">Stamps</option>
                            <option value="Spending">Spending</option>
                        </select>
                    </div>
                </div>

                <div className="text-center mt-3"><MDBDataTable data={data} paging={false} searching={false} noBottomColumns striped hover responsive bordered /></div>

            </div>
        );
    };
};


export default TopUser;