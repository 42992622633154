import { Pie } from 'react-chartjs-2';

const VoucherGivenChart = ({ voucherData }) => {

    let labels = [];
    let graphData = [];

    const allLabels = voucherData.map(voucher => voucher.from);

    for (let i = 0; i < allLabels.length; i++) {
        if (!labels.includes(allLabels[i])) {
            labels.push(allLabels[i]);
            const numberOfVoucher = voucherData.filter(voucher => voucher.from === allLabels[i]).length;
            graphData.push(numberOfVoucher);
        }
    }

    const data = {
        labels: labels,
        datasets: [{
            data: graphData,
            backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
                'rgb(178, 255, 89)',
            ],
        }]
    };

    const options = {
        plugins: {
            title: {
                display: true,
                text: "Number of voucher given to users"
            },
            subtitle: {
                display: true,
                text: `Total number of voucher given: ${graphData.reduce((a, b) => a + b, 0)}`
            }
        }
    };

    return (<Pie data={data} options={options} />);
}

export default VoucherGivenChart;