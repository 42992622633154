import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Topbar from './components/Topbar';
import Sidebar from "./components/Sidebar";
import Loading from '../../admin/components/Loading';
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const mySwal = withReactContent(Swal);

class UserDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            data: null,
            operatorId: "",
            loading: false
        };
    }

    componentDidMount() {
        if (!this.props.distributor) {
            return this.props.history.push('/distributor');
        }
    };

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleChange = e => this.setState({
        ...this.state,
        [e.target.id]: e.target.value
    });

    handleFilter = e => {
        const { operatorId } = this.state;

        if (operatorId) {

            this.setState({
                ...this.state,
                loading: true
            });
            axios
                .post("/api/distributor/getUsers", {operatorId})
                .then(res => {
                    this.setState({
                        ...this.state,
                        loading: false,
                        data: res.data.data
                    });
                })
                .catch(err => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    mySwal.fire("Error", err.response.data.error, "error");
                });
        } else {
            mySwal.fire("Info", "Please select operator", "info");
        }

    };

    handleReset = e => {
        this.setState({
            ...this.state,
            operatorId: "",
            data: null
        });
    };

    render() {
        let data = {
            columns: [
                {
                    label: "Name",
                    field: "name",
                    sort: "asc"
                },
                {
                    label: "Phone Number",
                    field: "number",
                    sort: "asc"
                },
                {
                    label: "Birthday",
                    field: "birthday",
                    sort: "asc"
                },
                {
                    label: "Age",
                    field: "age",
                    sort: "asc"
                },
                {
                    label: "Email Address",
                    field: "email",
                    sort: "asc"
                },
                {
                    label: "Member ID",
                    field: "memberId",
                    sort: "asc"
                },
                {
                    label: "Washer Stamp",
                    field: "washerStamp",
                    sort: "asc"
                },
                {
                    label: "Dryer Stamp",
                    field: "dryerStamp",
                    sort: "asc"
                },
                {
                    label: "Total Stamps",
                    field: "totalStamps",
                    sort: "asc"
                },
                {
                    label: "Vouchers Acquired",
                    field: "totalVouchers",
                    sort: "asc"
                },
                {
                    label: "Total Washer Payment (RM)",
                    field: "washerPayment",
                    sort: "asc"
                },
                {
                    label: "Total Dryer Payment (RM)",
                    field: "dryerPayment",
                    sort: "asc"
                },
                {
                    label: "Total Payment (RM)",
                    field: "totalPayment",
                    sort: "asc"
                },
                {
                    label: "e-Tokens",
                    field: "token",
                    sort: "asc"
                },
                {
                    label: "Bought e-Token using (RM)",
                    field: "tokenPayment",
                    sort: "asc"
                },
                {
                    label: "Total e-Token Purchased (Tokens)",
                    field: "tokenPurchased",
                    sort: "asc"
                },
                {
                    label: "Washer Payment (Tokens)",
                    field: "washerToken",
                    sort: "asc"
                },
                {
                    label: "Dryer Payment (Tokens)",
                    field: "dryerToken",
                    sort: "asc"
                },
                {
                    label: "Total Payment (Tokens)",
                    field: "tokenUsed",
                    sort: "asc"
                }
            ],
            rows: []
        };

        if (this.state.data) {
            for (let i = 0; i < this.state.data.length; i++) {
                const user = this.state.data[i];
                const dataObject = {
                    name: user.name,
                    number: user.number,
                    birthday: user.birthday,
                    age: user.age,
                    email: user.email,
                    memberId: user.memberId,
                    washerStamp: user.washerStamp,
                    dryerStamp: user.dryerStamp,
                    totalStamps: user.totalStamps,
                    totalVouchers: user.totalVouchers,
                    washerPayment: user.washerPayment,
                    dryerPayment: user.dryerPayment,
                    totalPayment: user.totalPayment,
                    token: user.token,
                    tokenPayment: user.tokenPayment,
                    tokenPurchased: user.tokenPurchased,
                    washerToken: user.washerToken,
                    dryerToken: user.dryerToken,
                    tokenUsed: user.tokenUsed
                };
                data.rows.push(dataObject);
            }
        }

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        {this.state.loading ? (
                            <Loading />
                        ) : (
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">User Details</h5>
                                </div>

                                <div className="card-body">
                                    <div className="mb-5">
                                        <h6>Filter</h6>
                                        <div>
                                            <label htmlFor="outletId">Select Operator: </label>
                                            <select id="operatorId" name="operatorId" className="browser-default form-select" value={this.state.operatorId} onChange={this.handleChange}>
                                                <option disabled value="">Select Operator</option>
                                                {
                                                    this.props.distributor.admin.map(admin => <option key={admin.operatorId} value={admin.operatorId}>{admin.username}</option>)
                                                }
                                            </select>
                                        </div>

                                        <div className="pt-2 d-flex">
                                            <button onClick={this.handleFilter} className="btn btn-primary btn-inline form-control me-3">
                                                Filter
                                            </button>
                                            <button onClick={this.handleReset} className="btn btn-secondary btn-inline form-control ms-3">
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                    <div className="text-center mt-5">{this.state.loading ? <Loading /> : <MDBDataTable data={data} noBottomColumns striped hover responsive bordered />}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        distributor: state.distributor.distributor
    };
};
export default connect(mapStateToProps, null)(UserDetails);
