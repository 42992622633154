import { useTranslation } from 'react-i18next';

const SettingDetails = ({ selected , handleBack , handleChange , handleSubmit , name , email/* , oldPassword*/ , newPassword , language, loading }) => {
    
    const { t } = useTranslation();
    
    const renderPage = () => {
        if (selected === "name") {
            return (
                <form id="name-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name">{t("Name")}</label>
                        <input type="text" id="name" name="name" placeholder="" value={name} onChange={handleChange} required />
                    </div>

                    <div className="mt-5">
                        <input id="change-name" type="submit" value={t("SUBMIT")} className="btn big-button form-control" />
                    </div>
                </form>
            );
        } else if (selected === "email") {
            return (
                <form id="email-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="email">{t("Email")}</label>
                        <input type="email" id="email" name="email" placeholder="" value={email} onChange={handleChange} required />
                    </div>

                    <div className="mt-5">
                        <input id="change-email" type="submit" value={t("SUBMIT")} className="btn big-button form-control" />
                    </div>
                </form>
            );
        } else if (selected === "password") {
            return (
                <form id="password-form" onSubmit={handleSubmit}>
                    {/* <div className="form-group">
                        <label htmlFor="oldPassword">Old Password</label>
                        <input type="text" id="oldPassword" name="oldPassword" placeholder="" value={oldPassword} onChange={handleChange} required />
                    </div> */}

                    <div className="form-group">
                        <label htmlFor="newPassword">{t("New Password")}</label>
                        <input type="text" id="newPassword" name="newPassword" placeholder="" value={newPassword} onChange={handleChange}  required/>
                    </div>

                    <div className="mt-5">
                        {
                            loading
                            ? (
                                <div className="text-center">
                                    <div className="spinner-border" style={{color : "#ffd109"}} role="status">
                                        <span className="sr-only">Loading ...</span>
                                    </div>
                                </div>
                            )
                            : (
                                <input id="change-password" type="submit" value={t("SUBMIT")} className="btn big-button form-control" />
                            )
                        }
                    </div>
                </form>
            );
        } else if (selected === "language") {
            return (
                <form id="language-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="language">{t("Language")}</label>
                        <select name="language" id="language" className="browser-default form-select" value={language} onChange={handleChange} required>
                                <option value="" disabled>{t("Select language")}</option>
                                <option value="en">English</option>
                                <option value="ms">Malay</option>
                                <option value="zh">Chinese</option>
                        </select>
                    </div>

                    <div className="mt-5">
                        <input id="change-language" type="submit" value={t("SUBMIT")} className="btn big-button form-control" />
                    </div>
                </form>
            );
        }
    }

    return (
        <div className="m-0 p-0">
            <div id="user-header">
                <h5><i onClick={e => handleBack()} className="fas fa-chevron-left me-3"></i> <strong>{t("Back")}</strong></h5>
            </div>

            <div className="p-5">
                {renderPage()}
            </div>
        </div>
    );
};

export default SettingDetails;