import { useState } from "react";

import { connect } from "react-redux";
//import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { MDBDataTable } from 'mdbreact';
// import Washer from "./dashboard/Washer";
// import Dryer from "./dashboard/Dryer";
// import Combined from "./dashboard/Combined";

import Sidebar from "../utilities/Sidebar";
//import NewsSlider from "./dashboard/NewsSlider";

const Dashboard = ({ user, transactions/*, history*/ }) => {
	//const [selected, setSelect] = useState("washer");
	const [toggled, setToggled] = useState(false);

	const { t } = useTranslation();

	let data = {
		columns: [
			{
				label: "Date",
				field: "date",
				sort: "asc"
			},
			{
				label: "Amount",
				field: "amount",
				sort: "asc"
			},
			{
				label: "Product",
				field: "product",
				sort: "asc"
			}
		],
		rows: []
	}

	if(transactions.length > 0) {
		for(let i = 0; i < transactions.length; i++) {
			const dataObject = {
				date: new Date(transactions[i].createdAt).toLocaleDateString("en-GB"),
				amount: (transactions[i].price).toFixed(2),
				product: transactions[i].name
			}
			data.rows.push(dataObject);
		}
	}

	// const selectColor = val => {
	// 	if (val === selected) {
	// 		return "selected-button";
	// 	} else {
	// 		return null;
	// 	}
	// };

	// const renderPage = () => {
	// 	if (user.combinedSetting) {
	// 		return <Combined />;
	// 	} else {
	// 		if (user.specificStamp === "both") {
	// 			if (selected === "washer") {
	// 				return <Washer />;
	// 			} else if (selected === "dryer") {
	// 				return <Dryer />;
	// 			} else {
	// 				return (
	// 					<div className="text-center">
	// 						<h1>{t("Something went wrong")} :/</h1>
	// 					</div>
	// 				);
	// 			}
	// 		} else {
	// 			if (user.specificStamp === "washerOnly") {
	// 				return <Washer />;
	// 			} else if (user.specificStamp === "dryerOnly") {
	// 				return <Dryer />;
	// 			} else {
	// 				return (
	// 					<div className="text-center">
	// 						<h1>{t("Something went wrong")} :/</h1>
	// 					</div>
	// 				);
	// 			}
	// 		}
	// 	}
	// };

	return (
		<div id="user-dashboard">
			<Sidebar toggled={toggled} setToggled={setToggled} />
			<div id="user-header">
				<h5>
					<i onClick={e => setToggled(true)} className="fas fa-bars me-3"></i> <strong>{t("Membership Program")}</strong>
				</h5>

				<div id="user-info-container">
					<div id="user-info-flex">
						{/* {!user.tokenSetting ? (
							<div id="user-pp" onClick={() => history.push("/user/setting")}>
								<div id="profile-picture">
									{user && user.profilePicture ? <img src={user ? user.profilePicture : ""} alt="" className="img-fluid" /> : <i id="temp-pp" className="fas fa-user"></i>}
								</div>
							</div>
						) : (
							<div style={{ height: "70px", display: "flex", flexDirection: "column", justifyContent: "space-around", borderRight: "1px white solid", paddingRight: "15px" }}>
								<h6 className="m-0 p-0">{t("Total e-Token")}</h6>
								<h3 className="m-0 p-0 mx-auto">
									<strong>{user.token}</strong>
								</h3>
							</div>
						)} */}
						<div style={{ height: "70px", display: "flex", flexDirection: "column", justifyContent: "space-around", borderRight: "1px white solid", paddingRight: "15px" }}>
							<h6 className="m-0 p-0">{t("Total e-Token")}</h6>
							<h3 className="m-0 p-0 mx-auto">
								<strong>{user ? user.token.toFixed(2) : null}</strong>
							</h3>
						</div>

						<div id="user-info">
							<h6 className="m-0 p-0">{user ? user.name : null}</h6>
							<h5 className="m-0 p-0"><strong>{user ? user.number : null}</strong></h5>
							{/* <div className="d-flex m-0 p-0">
								<p className="m-0 p-0">
									<small>
										{t("Total Wash")} :{" "}
										<span style={{ color: "#ffd109" }}>
											<strong>{user ? user.totalWash : 0}</strong>
										</span>
									</small>
								</p>
								<p className="m-0 p-0 ms-3">
									<small>
										{t("Total Dry")} :{" "}
										<span style={{ color: "#ffd109" }}>
											<strong>{user ? user.totalDry : 0}</strong>
										</span>
									</small>
								</p>
							</div> */}
						</div>
					</div>

					{/* {user.tokenSetting ? (
						<div>
							<Link to="/user/reload" style={{textDecoration: "none"}}>
								<div id="reload" className="reload-button">
									<i className="fas fa-plus"></i> {t("Reload")}
								</div>
							</Link>
						</div>
					) : null} */}
				</div>
			</div>

			<div id="user-body">
			<MDBDataTable data={data} noBottomColumns striped hover responsive bordered paging={false} searching={false} />
				{/* {user.combinedSetting || user.specificStamp !== "both" ? null : (
					<div className="px-3 mt-4">
						<div id="type-selector">
							<button id="washer" onClick={e => setSelect("washer")} className={selectColor("washer")}>
								{t("Washer")}
							</button>
							<button id="dryer" onClick={e => setSelect("dryer")} className={selectColor("dryer")}>
								{t("Dryer")}
							</button>
						</div>
					</div>
				)}

				<div className="mt-3">{renderPage()}</div> */}

				{/* <div>
					<h5>
						<strong>{t("News")}</strong>
					</h5>
					<div>
						<NewsSlider page="dashboard" history={history} />
					</div>
				</div> */}
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.user.user,
		transactions: state.user.user?.purchases || []
	};
};

export default connect(mapStateToProps, null)(Dashboard);
