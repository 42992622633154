import { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import ReloadList from "./reload/ReloadList";
import PurchaseHistory from "./reload/PurchaseHistory";
import { useTranslation } from 'react-i18next';

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const mySwal = withReactContent(Swal);

const Reload = ({ history, user, token }) => {
	const [selected, setSelect] = useState("reload");
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(null);

	const { t } = useTranslation();

	useEffect(() => {
		if (!user) history.push("/");
		else
			axios
				.get(`/api/token/fetchPackages?operatorId=${user.store}`, { headers: { "Content-Type": "application/json", "auth-token": token } })
				.then(res => {
					setData(res.data);
					setLoading(false);
				})
				.catch(err => {
					mySwal.fire("Error", err.response.data.error, "error");
					setLoading(false);
				});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const selectColor = val => (val === selected ? "selected-button" : null);

	const handleSubmit = pkg => {
		mySwal
			.fire({
				title: t("Buy this package?"),
				html: `
                ${t("Purchase")} ${pkg.token} ${t("e-Tokens for")} RM ${parseFloat(pkg.price).toFixed(2)}?
            `,
				icon: "question",
				showCancelButton: true,
				cancelButtonText: t("Cancel"),
				confirmButtonText: t("Purchase"),
				cancelButtonColor: "#d33",
				customClass: {
					confirmButton: 'purchase-token',
				},
			})
			.then(click => {
				if (click.isConfirmed && click.value) {
					const userId = user._id;
					const pkgId = pkg._id;
					const sendThis = {
						userId,
						pkgId
					};
					setLoading(true);
					axios
						.post("/api/token/purchase", sendThis, { headers: { "Content-Type": "application/json", "auth-token": token } })
						.then(res => {
							setLoading(false);
							console.log(res);
							if (!res.data.data) {
								history.push("/");
							} else {
								window.location.href = res.data.data;
							}
						})
						.catch(err => {
							mySwal.fire("Error", err.response.data.error, "error");
							setLoading(false);
						});
				}
			});
	};

	const renderPage = selected === "reload" ? <ReloadList data={data} loading={loading} handleSubmit={handleSubmit} /> : <PurchaseHistory purchaseHistory={user.purchaseHistory} />;

	return (
		<div id="reload-page">
			<div id="user-header">
				<h5>
					<i onClick={e => history.push("/")} className="fas fa-chevron-left me-3"></i> <strong>{t("Reload Tokens")}</strong>
				</h5>

				<div id="token-info-container">
					<div id="token-image-container">
						<i className="fas fa-coins"></i>
					</div>

					<div id="token-info">
						<h3>{user?.name || ""}</h3>
						<h6>
							{t("Current e-Token")}: <span id="user-token">{user?.token || 0}</span>
						</h6>
					</div>
				</div>
			</div>

			<div id="user-body" className="mt-5">
				<div id="type-selector">
					<button id="reload" onClick={e => setSelect("reload")} className={selectColor("reload")}>
						{t("Reload")}
					</button>
					<button id="history" onClick={e => setSelect("history")} className={selectColor("history")}>
						{t("History")}
					</button>
				</div>

				<div className="mt-3">{renderPage}</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.user.user,
		token: state.user.token
	};
};

export default connect(mapStateToProps, null)(Reload);
