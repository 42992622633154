import React, { Component } from "react";
import axios from "axios";
import Topbar from './components/Topbar';
import Sidebar from "./components/Sidebar";
import Loading from '../../admin/components/Loading';
import { MDBDataTable } from "mdbreact";

class TokenDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			toggled: false,
			loading: true
		};
	}

	componentDidMount() {
		if (!this.props.location.state) {
			this.props.history.push("/distributor");
		} else {
			axios
				.post("/api/distributor/report/token/detail", this.props.location.state)
				.then(res => {
					this.setState({
						...this.state,
						data: res.data,
						loading: false
					});
				})
				.catch(err => this.props.history.push("/distributor"));
		}
	}

	handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

	render() {
		let tableData = {
			columns: [
				{
					label: "User Name",
					field: "name",
					sort: "asc"
				},
				{
					label: "Phone Number",
					field: "number",
					sort: "asc"
				},
				{
					label: "Email Address",
					field: "email",
					sort: "asc"
				},
				{
					label: "Member ID",
					field: "memberId",
					sort: "asc"
				},
				{
					label: "e-Token Spent",
					field: "tokenSpent",
					sort: "asc"
				},
				{
					label: "Machine Type",
					field: "machineType",
					sort: "asc"
				},
				{
					label: "Transaction ID",
					field: "transactionId",
					sort: "asc"
				},
				{
					label: "Transaction Date",
					field: "date",
					sort: "asc"
				}
			],
			rows: this.state.data
		};

		return (
			<div className="admin-page-container">
				<Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
				<div className="admin-page">
					<Topbar handleToggle={this.handleToggle} />
					<div className="admin-content">
						<div className="card">
							<div className="card-header">
								<h5 className="card-title">e-Token Detailed Report ({this.props.location.state.outletName})</h5>
							</div>

							<div className="card-body">
								{this.state.loading ? (
									<Loading />
								) : (
									<div>
										<MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default TokenDetail;
