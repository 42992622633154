import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';
import queryString from "query-string";
import axios from "axios";

//import paymentWasher from "../../../assets/images/machines/paymentWasher.png";
//import paymentDryer from "../../../assets/images/machines/paymentDryer.png";
//import vendingMachine from "../../../assets/images/machines/vendingMachine.png";

//import WasherPayment from "./payment/WasherPayment";
//import DryerPayment from "./payment/DryerPayment";
//import VendingMachine from "./payment/VendingMachine";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { /*changePage,*/checkToken } from "../../../redux/actions/userActions";
import { /*scannedCode, saveCreds,*/savePaymentProduct } from "../../../redux/actions/paymentActions";

const mySwal = withReactContent(Swal);

const Payment = ({ payment, user, history, /*changePage,*/ location, savePaymentProduct, checkToken, token /*scanned, saveCredentials*/ }) => {

	const { t } = useTranslation();

	const { TID, Token } = queryString.parse(location.search);

	useEffect(() => {
		if (!user || !payment.product) {
			if (!user) {
				if (token) {
					checkToken();
				} else {
					history.push('/payment');
				}
			}
			if (!payment.product) {
				if (TID && Token) {
					axios.post("/api/user/purchase/frame", { TID, Token })
						.then(res => {
							savePaymentProduct(res.data.data);
						})
						.catch(err => {
							mySwal.fire("Error", err.response.data.error, "error");
						});
				} else {
					history.push('/payment');
				}
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// 	if (!user) {
	// 		mySwal
	// 			.fire({
	// 				title: t("Join as a member now!"),
	// 				html: t("Join as a loyalty member now to get more benefits such as vouchers and free machine usage!<br /><br /><strong>Register as loyalty member?</strong>"),
	// 				icon: "info",
	// 				showCancelButton: true,
	// 				cancelButtonText: t("Maybe Next Time"),
	// 				confirmButtonText: t("Yes, Register Now"),
	// 				cancelButtonColor: "#d33",
	// 				customClass: {
	// 					confirmButton: 'register-now',
	// 					cancelButton: 'register-next-time'
	// 				},
	// 			})
	// 			.then(click => {
	// 				if (click.isConfirmed && click.value) {
	// 					changePage("register");
	// 					history.goBack();
	// 				}
	// 			});

	// 		return function cleanup() {
	// 			mySwal.close();
	// 		};
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const handleSubmit = e => {
		if (user) {
			mySwal.fire({
				title: "Confirmation",
				text: `Pay RM${payment.product.price} to purchase ${payment.product.name}?`,
				icon: "question",
				showCancelButton: true
			})
				.then(click => {
					if (click.isConfirmed) {
						mySwal
							.fire({
								text: t("Processing ..."),
								allowOutsideClick: false,
								didOpen: () => {
									mySwal.showLoading();
									axios
										.post('/api/user/purchase/createTrans', { TID, Token, number: user.number })
										.then(res => {
											if (res.data.status === "Success") {
												e.target.disabled = false;
												mySwal.close();
												history.push("/thankyou");
											}
										})
										.catch(err => {
											mySwal.fire("Error", err.response.data.error, "error").then(() => (e.target.disabled = false));
										});
								}
							})
							.then(() => {
								e.target.disabled = false;
							});
					}
				})
		} else {
			mySwal.fire(t("Info"), t("Please login again"), "info");
		}
	}

	return (
		<div id="payment-page">
			<div id="user-header">
				<h5>
					<i onClick={e => history.push("/payment")} className="fas fa-chevron-left me-3"></i>
					<strong>Membership Payment</strong>
				</h5>

				{/* <div id="machine-info-container">
						<div id="machine-image-container">
							{payment.machine ? payment.machine.type === "Washer" ? (
								<img src={paymentWasher} alt="washer" className="img-fluid" />
							) : payment.machine.type === "Dryer" ? (
								<img src={paymentDryer} alt="dryer" className="img-fluid" />
							) : (
								<img src={vendingMachine} alt="dryer" className="img-fluid" />
							) : null}
						</div>

						<div id="machine-info">
							{payment.machine ? payment.machine.type === "Vending Machine" ? (
								<>
									<h5 className="m-0 p-0">{payment.machine.name}</h5>
									<h6 className="m-0 p-0">{t(payment.machine.type)}</h6>
									<div id="badge-container" className="mt-2">
										<div
											className="status-badge"
											style={
												payment.machine.online && payment.machine.outletStatus === "online"
													? { backgroundColor: process.env.REACT_APP_STORETYPE === "cuci" ? "#ffd109" : "#8be78b" }
													: { backgroundColor: "#d33" }
											}
										>
											<strong>{payment.machine.online && payment.machine.outletStatus ? t("Online") : t("Offline")}</strong>
										</div>

										<div className="status-badge" style={payment.machine.running ? { backgroundColor: "#d33" } : null}>
											<strong>{payment.machine.running ? t("Running") : t("Vacant")}</strong>
										</div>
									</div>
								</>
							) : (
								<>
									<h5 className="m-0 p-0">
										{t(payment.machine.type)} - {payment.machine.name}
									</h5>
									<h6 className="m-0 p-0">{payment.machine.capacity}</h6>
									<div id="badge-container" className="mt-2">
										<div
											className="status-badge"
											style={
												payment.machine.online && payment.machine.outletStatus === "online"
													? { backgroundColor: process.env.REACT_APP_STORETYPE === "cuci" ? "#ffd109" : "#8be78b" }
													: { backgroundColor: "#d33" }
											}
										>
											<strong>{payment.machine.online && payment.machine.outletStatus === "online" ? t("Online") : t("Offline")}</strong>
										</div>

										<div className="status-badge" style={payment.machine.running ? { backgroundColor: "#d33" } : null}>
											<strong>{payment.machine.running ? t("Running") : t("Vacant")}</strong>
										</div>
									</div>
								</>
							) : null}
						</div>
					</div> */}
			</div>

			<div id="user-body">
				{
					payment.product ? (
						<div className="text-center">
							<img src={payment.product.imageUrl} alt="product" style={{ width: "40%" }} />
						</div>
					) : null
				}
				{/* {payment.machine ? payment.machine.type === "Washer" ? (
						<WasherPayment machine={payment.machine} outlet={payment.outlet} user={user} history={history} />
					) : payment.machine.type === "Dryer" ? (
						<DryerPayment machine={payment.machine} outlet={payment.outlet} user={user} history={history} />
					) : (
						<VendingMachine machine={payment.machine} outlet={payment.outlet} user={user} history={history} />
					) : null} */}

				<div className="list-group payment-list-group">
					<div className="list-group-item payment-list-group-item">
						<div>
							<p className="text-secondary">Product</p>
						</div>

						<div>
							<p>{payment.product ? payment.product.name : ""}</p>
						</div>
					</div>
					<div className="list-group-item payment-list-group-item">
						<div>
							<p className="text-secondary">Stock</p>
						</div>

						<div>
							<p>{payment.product ? payment.product.stock : ""}</p>
						</div>
					</div>
					<div className="list-group-item payment-list-group-item">
						<div>
							<p style={{ fontSize: "24px", fontWeight: "bolder" }} className="text-secondary">
								PRICE
							</p>
						</div>

						<div>
							<p style={{ fontSize: "24px", fontWeight: "bolder" }}>RM {payment.product ? /\./g.test(payment.product.price.toString()) ? payment.product.price.toFixed(2) : payment.product.price : ""}</p>
						</div>
					</div>
				</div>

				<div className="mt-5 px-3">
					<button id="pay" onClick={e => handleSubmit(e)} className="btn form-control big-button">
						{t("PAY")}
					</button>
				</div>
			</div>
		</div>
	);

};

const mapStateToProps = state => {
	return {
		payment: state.payment,
		user: state.user.user,
		token: state.user.token
	};
};

const mapDispatchToProps = dispatch => {
	return {
		//changePage: data => dispatch(changePage(data)),
		checkToken: () => dispatch(checkToken()),
		savePaymentProduct: data => dispatch(savePaymentProduct(data))
		//scanned: data => dispatch(scannedCode(data)),
		//saveCredentials: data => dispatch(saveCreds(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
