import { IS_LOADING , IS_LOADED , SHOW_ERROR , CLEAR_ERROR , USER_LOGIN , REMOVE_TOKEN , UPDATE_USER , CHANGE_PAGE , SAVE_OPERATORID, CHANGE_LANGUAGE, CHANGE_NAV, CHANGE_COUNTER } from './type';
import { userTokenConfig } from './helperFunction';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const mySwal = withReactContent(Swal);

// change page
export const changePage = data => {
    return {
        type : CHANGE_PAGE,
        payload : data
    };
};

// change navigation
export const changeNav = data => {
    return {
        type : CHANGE_NAV,
        payload : data
    };
};

// change modal counter
export const changeCounter = data => {
    return {
        type : CHANGE_COUNTER,
        payload : data
    };
};

// register
export const registerUser = data => dispatch => {
    dispatch({type : CLEAR_ERROR});
    dispatch({type : IS_LOADING});
    axios.post('/api/user/auth/register' , data)
    .then(res => {
        dispatch({type : USER_LOGIN , payload : res.data.data});
        dispatch({type : IS_LOADED});
    })
    .catch(err => {
        dispatch({type : SHOW_ERROR , payload : err.response.data.error});
        dispatch({type : IS_LOADED});
    });
};

// login
export const loginUser = data => dispatch => {
    dispatch({type : CLEAR_ERROR});
    dispatch({type : IS_LOADING});
    axios.post('/api/user/auth/login' , data)
    .then(res => {
        if (res.data.reset) mySwal.fire("", "Please go to your account setting and reset your password", "info");
        dispatch({type : USER_LOGIN , payload : res.data.data});
        dispatch({type : IS_LOADED});
    })
    .catch(err => {
        dispatch({type : SHOW_ERROR , payload : err.response.data.error});
        dispatch({type : IS_LOADED});
    });
};

// check token for session
export const checkToken = () => (dispatch , getState) => {
    if (getState().user.token) {
        axios.post('/api/user/auth/checkToken' , {token : getState().user.token})
        .then(res => dispatch({type : USER_LOGIN , payload : res.data.data}))
        .catch(err => {
            console.log(err);
            dispatch({type : REMOVE_TOKEN});
        });
    }
};

// logout user
export const logoutUser = () => {
    return {
        type : REMOVE_TOKEN
    };
};

// update profile picture
export const updateUser = data => {
    return {
        type : UPDATE_USER,
        payload : data
    };
};

// grant voucher
export const grantVoucher = data => (dispatch, getState) => {
    axios.post('/api/user/grantVoucher', data, userTokenConfig(getState))
    .then(res => dispatch(updateUser({data : res.data.data , type : "stamp"})))
    .catch(err => dispatch({type : SHOW_ERROR , payload : err.response.data.error}));
};

export const saveOperatorId = id => {
    return {
        type : SAVE_OPERATORID,
        payload : id
    };
};


// functions
export const resendOtp = data => {
    axios.post('/api/user/auth/sendOtp' , data)
    .then(res => console.log("OTP sent", res.data))
    .catch(err => console.error({err: err.response}));
};

// change language
export const changeLanguage = language => {
    return {
        type : CHANGE_LANGUAGE,
        payload : language
    };
};