import React from 'react';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';

// import ReactNotification, { store } from 'react-notifications-component';

import LandingPage from './components/user/landingPage/LandingPage';
import PrivacyPolicy from './components/user/agreement/PrivacyPolicy';
import TermsConditions from './components/user/agreement/TermsConditions';
import UserFaq from './components/user/utilities/UserFaq';
import Main from './components/user/main/Main';
import UserUtils from './components/user/utilities/UserUtils';
import Assistant from './components/assistant/Assistant';
import Admin from './components/admin/Admin';
import Dev from './components/dev/Dev';
import Distributor from './components/distributor/Distributor';
import Thankyou from './components/user/utilities/Thankyou';
import IframePayment from './components/user/iframe/IframePayment';

function App({ sw, theme }) {
  return (
    <BrowserRouter>
      <div className={theme}>
        
        <Switch>
          <Route exact path="/iframe" component={IframePayment} />
          <Route exact path="/payment" component={Main} />
          <Route exact path="/welcome" component={LandingPage} />
          <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
          <Route exact path="/termsConditions" component={TermsConditions} />
          <Route exact path="/faq" component={UserFaq} />
          <Route exact path="/thankyou" component={Thankyou} />
          <Route path="/user" component={UserUtils} />
          <Route path="/admin" component={Admin} />
          <Route path="/dev" component={Dev} />
          <Route path="/distributor" component={Distributor} />
          <Route exact path="/assistant" component={Assistant} />
          <Redirect to="/iframe" />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

const mapStateToProps = state => {
  return {
    sw: state.serviceWorker,
    theme: state.theme.theme
  };
};

export default connect(mapStateToProps, null)(App);
