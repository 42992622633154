import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import queryString from "query-string";
import axios from 'axios';
import { Spinner } from 'react-bootstrap';

import alipay from "../../../assets/images/logo/alipay.png";
import boost from "../../../assets/images/logo/boost.png";
import presto from "../../../assets/images/logo/presto.png";
import tng from "../../../assets/images/logo/tng.png";

const IframePayment = () => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [product, setProduct] = useState([]);
    const [url, setUrl] = useState("");
    const location = useLocation();

    const { TID, Token } = queryString.parse(location.search);

    useEffect(() => {
        if (TID && Token) {
            setError("");
            setLoading(true);
            axios.post('/api/user/purchase/frame', { TID, Token })
                .then(res => {
                    setProduct(res.data.data);
                    axios.post('/api/user/purchase/rmPayment', { TID, Token })
                        .then(res => {
                            setUrl(res.data.url);
                            setLoading(false);
                        })
                        .catch(err => {
                            setError(err.response.data.error);
                            setLoading(false);
                        });
                })
                .catch(err => {
                    setError(err.response.data.error);
                    setLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container text-center">
            {
                loading ? <Spinner style={{ position: "absolute", top: "50%", left: "50%" }} animation="border" variant="primary" /> : (
                    <div>
                        {
                            error ? (
                                <div>
                                    <p style={{ color: "red", paddingTop: "5px" }}>{error}</p>
                                </div>
                            ) : null
                        }
                        <div className="pt-3">
                            <img src={product.imageUrl} alt="product" style={{ width: "15%" }} />
                            <h5 className="pt-2">{product.name}</h5>
                            <h5>Price: RM {product.price}</h5>
                            <h5>Stock: {product.stock}</h5>
                        </div>
                        <div className="d-flex pt-3">
                            <div style={{ width: "50%" }}>
                                <h5>Membership payment</h5>
                                <img src={product.memberUrl} alt="qrcode" style={{ width: "50%" }} />
                            </div>
                            <div style={{ width: "50%" }}>
                                <h5>E-Wallet payment</h5>
                                <div className="d-flex justify-content-around">
                                    <img src={alipay} alt="alipay" style={{ width: "20%", height: "20%" }} />
                                    <img src={boost} alt="boost" style={{ width: "20%", height: "20%" }} />
                                    <img src={presto} alt="presto" style={{ width: "20%", height: "20%" }} />
                                    <img src={tng} alt="tng" style={{ width: "20%", height: "20%" }} />
                                </div>
                                <button id="iframe-ewallet" className="big-button form-control btn py-3 mt-3" onTouchStart={() => window.location = url}>Pay now</button>
                                {/* <a href={url} id="iframe-ewallet" className="big-button form-control btn py-3 mt-3">Pay now</a> */}
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default IframePayment;