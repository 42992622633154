import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

class AddDistributor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            distributorId: "",
            email: "",
            username: "",
            password: "",
            loading: true,
            distributors: []
        };
    };

    componentDidMount() {
        if (this.props.dev.isAuthenticated) {
            axios
                .get("/api/dev/get/laundroDistributor", { headers: { "Content-Type": "application/json", "auth-token": this.props.dev.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        distributors: res.data.data,
                        loading: false
                    });

                })
                .catch(err => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    alert(err.response.data.error);
                });
        }
    }

    handleSelectDistributor = e => {
        const allDistributors = this.state.distributors;
        const selectedDistributors = allDistributors.filter(distributor => distributor.franchisorId === e.target.value)[0];

        this.setState({
            ...this.state,
            distributorId: e.target.value,
            email: selectedDistributors.email,
            username: "",
            password: ""
        });
    };

    handleChange = e => {
        if (this.state.distributorId) {
            this.setState({
                ...this.state,
                [e.target.id]: e.target.value
            });
        }
    };

    handleSubmit = e => {
        e.preventDefault();

        const { distributorId, email, username, password } = this.state;
        if (distributorId && email && username && password) {
            this.setState({
                ...this.state,
                loading: true
            });

            const sendThis = {
                distributorId,
                email,
                username,
                password
            };

            axios.post('/api/dev/registerDistributor', sendThis, {headers: {"Content-Type" : "application/json", "auth-token" : this.props.dev.token}})
            .then(res => {
                this.setState({
                    ...this.state,
                    distributorId: "",
                    email: "",
                    username: "",
                    password: "",
                    loading: false,
                });
                alert("Success");
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    loading : false
                });
                alert(err.response.data.error);
            });

        }
    };

    render() {
        if (!this.props.dev.isLoading && this.props.dev.error) {
            return (
                <div className="text-center pt-5">
                    <h1>{this.props.dev.error}</h1>
                </div>
            )
        } else {
            return (
                <div className="pt-5 px-5">
                    <form className="mt-4" onSubmit={this.handleSubmit}>
                        <div className="text-center">
                            <h1>Add Distributor</h1>
                        </div>
                        <div className="form-group mt-5">
                            <label htmlFor="distributorId">Distributors</label>
                            <select name="distributorId" id="distributorId" className="browser-default form-select" value={this.state.distributorId} onChange={this.handleSelectDistributor}>
                                <option value="" disabled>Select a distributor</option>
                                {this.state.distributors.map(distributor => <option key={distributor.franchisorId} data={distributor} value={distributor.franchisorId}>{distributor.userName} ({distributor.franchisorId})</option>)}
                            </select>
                        </div>

                        <hr />

                        <div>
                            <div className="form-group">
                                <label htmlFor="email">Email:</label>
                                <input type="email" name="email" id="email" className="browser-default form-control" value={this.state.email} onChange={this.handleChange} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="username">Username: (for distributor login)</label>
                                <input type="text" name="username" id="username" className="browser-default form-control" value={this.state.username} onChange={this.handleChange} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="password">Password: (for distributor login)</label>
                                <input type="text" name="password" id="password" className="browser-default form-control" value={this.state.password} onChange={this.handleChange} required />
                            </div>
                        </div>

                        <div>
                            {
                                this.state.loading
                                    ? (
                                        <div className="text-center mt-5">
                                            <p>Loading ...</p>
                                        </div>
                                    )
                                    : <input type="submit" value="SUBMIT" className="btn btn-primary form-control font-weight-bold mt-5" />
                            }
                        </div>
                    </form>
                </div>
            );
        }
    };
};

const mapStateToProps = state => {
    return {
        dev: state.dev
    };
};

export default connect(mapStateToProps, null)(AddDistributor);