import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Topbar from './components/Topbar';
import Sidebar from "./components/Sidebar";
import Loading from '../../admin/components/Loading';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { MDBDataTable } from 'mdbreact';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const mySwal = withReactContent(Swal);

class StorePerformance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            data: null,
            loading: false,
            outletId: "",
            startDate: "",
            endDate: "",
            operatorId: "",
            outlets: []
        };
    };

    componentDidMount() {
        if (!this.props.distributor) {
            return this.props.history.push('/distributor');
        }
    };

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleChange = e => this.setState({
        ...this.state,
        [e.target.id]: e.target.value
    });

    handleChangeOperator = e => {

        const selectedOutlet = this.props.distributor.outlet.filter(outlet => outlet.operatorId === e.target.value);

        this.setState({
            ...this.state,
            outlets: selectedOutlet,
            [e.target.id]: e.target.value
        });
    }

    handleDate = dates => {
        const [start, end] = dates;
        this.setState({
            ...this.state,
            startDate: start,
            endDate: end
        });
    };

    handleFilter = e => {
        const { outletId, startDate, endDate, operatorId } = this.state;
        let operatorIds = [];
        for(let i = 0; i < this.props.distributor.admin.length; i++){
            operatorIds.push(this.props.distributor.admin[i].operatorId);
        }
        let sendThis = {
            operatorIds
        };

        if (operatorId) {
            sendThis.operatorId = operatorId;
        }
        if (outletId) {
            sendThis.outletId = outletId;
        }
        if (startDate && endDate) {
            sendThis.startDate = new Date(startDate);
            sendThis.endDate = new Date(endDate).setHours(23, 59, 59, 999);
        }
        this.setState({
            ...this.state,
            loading: true
        });
        axios.post('/api/distributor/report/store', sendThis)
            .then(res => {
                this.setState({
                    ...this.state,
                    loading: false,
                    data: res.data.data
                });
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                mySwal.fire("Error", err.response.data.error, "error");
            });
    };

    handleReset = e => {
        this.setState({
            ...this.state,
            outletId: "",
            startDate: "",
            endDate: "",
            operatorId: "",
            data: null,
            outlets: []
        });
    };

    render() {
        let data = {
            columns: [
                {
                    label: "Store",
                    field: "outletName",
                    sort: "asc"
                },
                {
                    label: "Stamps Granted",
                    field: "totalStamps",
                    sort: "asc"
                },
                {
                    label: "Voucher Claimed",
                    field: "voucherClaimed",
                    sort: "asc"
                },
                {
                    label: "Washer Usage",
                    field: "washerUsed",
                    sort: "asc"
                },
                {
                    label: "Dryer Usage",
                    field: "dryerUsed",
                    sort: "asc"
                }
            ],
            rows: this.state.data ?? []
        };


        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Store Performance</h5>
                            </div>

                            <div className="card-body">
                                <div className="mb-5">
                                    <h6>Filter</h6>
                                    <div>
                                        <label htmlFor="outletId">Select Operator: </label>
                                        <select id="operatorId" name="operatorId" className="browser-default form-select" value={this.state.operatorId} onChange={this.handleChange}>
                                            <option disabled value="">Select Operator</option>
                                            {
                                                this.props.distributor.admin.map(admin => <option key={admin.operatorId} value={admin.operatorId}>{admin.username}</option>)
                                            }
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="outletId">Select Outlet: </label>
                                        <select id="outletId" name="outletId" className="browser-default form-select" value={this.state.outletId} onChange={this.handleChange}>
                                            <option disabled value="">Select Outlet</option>
                                            {
                                                this.state.operatorId ? this.state.outlets.map(outlet => <option key={outlet.outletId} value={outlet.outletId}>{outlet.outletName}</option>) : this.props.distributor.outlet.map(outlet => <option key={outlet.outletId} value={outlet.outletId}>{outlet.outletName}</option>)
                                            }
                                        </select>
                                    </div>

                                    <div>
                                        <label htmlFor="date">Select Date: </label>
                                        <DatePicker value={`${this.state.startDate ? new Date(this.state.startDate).toLocaleDateString() : ""} - ${this.state.endDate ? new Date(this.state.endDate).toLocaleDateString() : ""}`} onChange={this.handleDate} startDate={this.state.startDate} endDate={this.state.endDate} selectsRange shouldCloseOnSelect={false} />
                                    </div>

                                    <div className="d-flex">
                                        <button onClick={this.handleFilter} className="btn btn-primary btn-inline form-control me-3">Filter</button>
                                        <button onClick={this.handleReset} className="btn btn-secondary btn-inline form-control ms-3">Reset</button>
                                    </div>
                                </div>
                                <div className="text-center mt-5">
                                    {
                                        this.state.loading ? <Loading /> : <MDBDataTable data={data} noBottomColumns striped hover responsive bordered />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        distributor: state.distributor.distributor
    };
};

export default connect(mapStateToProps, null)(StorePerformance);