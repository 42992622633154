import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';

const UserByMonthChart = ({ userData, admin }) => {

    let labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let datasets = [];

    function generateRandomColor() {
        let letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }

        return color;
    }

    let totalData = [];
    for (let i = 0; i < 12; i++) {
        const month = userData.filter(user => new Date(user.createdAt).getMonth() === i).length;
        totalData.push(month);
    }
    const totalDataset = {
        label: "Total",
        data: totalData,
        fill: false,
        borderColor: generateRandomColor(),
        tension: 0.3
    }
    datasets.push(totalDataset);

    for (let i = 0; i < admin.outlets.length; i++) {
        let graphData = [];

        const users = userData.filter(data => data.outletCode === admin.outlets[i].outletId);

        for (let j = 0; j < 12; j++) {
            const month = users.filter(user => new Date(user.createdAt).getMonth() === j).length;
            graphData.push(month);
        }

        const dataset = {
            label: admin.outlets[i].outletName,
            data: graphData,
            fill: false,
            borderColor: generateRandomColor(),
            tension: 0.3,
            hidden: true
        }
        datasets.push(dataset);
    }

    let generalData = [];
    const generalUser = userData.filter(data => data.outletCode === "-");
    for (let j = 0; j < 12; j++) {
        const month = generalUser.filter(user => new Date(user.createdAt).getMonth() === j).length;
        generalData.push(month);
    }
    const generalDataset = {
        label: "Registered",
        data: generalData,
        fill: false,
        borderColor: generateRandomColor(),
        tension: 0.3,
        hidden: true
    }
    datasets.push(generalDataset);

    const data = {
        labels: labels,
        datasets: datasets
    };

    const options = {
        plugins: {
            title: {
                display: true,
                text: "Number of registered users"
            }
        }
    };

    return (<Line data={data} options={options} />);
}

const mapStateToProps = state => {
    return {
        admin: state.admin.admin
    };
};

export default connect(mapStateToProps, null)(UserByMonthChart);