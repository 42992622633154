import { /*SCANNED_QRCODE, SAVE_CREDS, */SAVE_PAYMENT_PRODUCT } from "../actions/type";

const initState = {
	product: null,
	//machine: null,
	//outlet: null
};

const paymentReducer = (state = initState, action) => {
	switch (action.type) {
		// case SCANNED_QRCODE:
		// 	return {
		// 		...state,
		// 		machine: action.payload
		// 	};
		// case SAVE_CREDS:
		// 	return {
		// 		...state,
		// 		outlet: action.payload
		// 	};
		case SAVE_PAYMENT_PRODUCT:
			return {
				...state,
				product: action.payload
			}
		default:
			return state;
	}
};

export default paymentReducer;
