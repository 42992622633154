import { useEffect } from 'react';
import { connect } from 'react-redux';
import DevLogin from './pages/Login';
import DevDashboard from './pages/DevDashboard';
import { checkToken } from "../../redux/actions/devActions";

const Dev = ({ isAuthenticated, checkToken }) => {

    useEffect(() => {
        checkToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <div id="dev-page">
            {
                isAuthenticated
                ? <DevDashboard />
                : <DevLogin />
            }
        </div>
    );
    
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.dev.isAuthenticated
    };
};

const mapDispatchToProps = dispatch => {
	return {
		checkToken: () => dispatch(checkToken()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dev);