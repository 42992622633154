import { useEffect } from 'react';
import { connect } from 'react-redux';
import DistributorLogin from './pages/Login';
import DistributorDashboard from './pages/DistributorDashboard';
import { checkToken, distributorTokenLogin } from "../../redux/actions/distributorActions";
import queryString from 'query-string';

const Distributor = ({ isAuthenticated, checkToken, location, tokenLogin }) => {

    useEffect(() => {
        const { token } = queryString.parse(location.search);
        if (token) {
            tokenLogin({ token });
        } else {
            checkToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {
                isAuthenticated
                    ? <DistributorDashboard />
                    : <DistributorLogin />
            }
        </div>
    );
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.distributor.isAuthenticated
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkToken: () => dispatch(checkToken()),
        tokenLogin: token => dispatch(distributorTokenLogin(token))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Distributor);