import React, { Component } from "react";
import { connect } from "react-redux";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import Loading from "../components/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { enableReferral, updateReferral } from "../../../redux/actions/adminActions";

const mySwal = withReactContent(Swal);

const colorSets = [
    {
        headColor: "#c596c4",
        bodyColor: "linear-gradient(135deg , #c8bfde , #a1c3e9)"
    },
    {
        headColor: "#fbcc7d",
        bodyColor: "linear-gradient(135deg , #f6b997 , #f38695)"
    },
    {
        headColor: "#93c9ed",
        bodyColor: "linear-gradient(135deg , #6599d3 , #77cebb)"
    },
    {
        headColor: "#c7a1aa",
        bodyColor: "linear-gradient(135deg , #f2d0e0 , #c6a9d2)"
    },
    {
        headColor: "#b6da98",
        bodyColor: "linear-gradient(135deg , #cee6a9 , #dbde91)"
    }
];

const randomNumber = Math.floor(Math.random() * colorSets.length);
const selectedColor = colorSets[randomNumber];

class ReferralSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            referral: false,
            voucher: [],
            token: [],
            stamp: [],
            numberOfReferral: 0,
            amountTransaction: 0,
            loading: false,
        };
    }

    componentDidMount() {
        if (!this.props.admin.isAuthenticated) {
            return this.props.history.push("/admin/dashboard");
        } else {
            this.setState({
                ...this.state,
                referral: this.props.admin.admin.referral,
                voucher: this.props.admin.admin.referralSetting.voucher,
                token: this.props.admin.admin.referralSetting.token,
                stamp: this.props.admin.admin.referralSetting.stamp,
                numberOfReferral: this.props.admin.admin.referralSetting.numberOfReferral,
                amountTransaction: this.props.admin.admin.referralSetting.amountTransaction
            });
        }
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleEnable = e => {
        axios
            .post("/api/admin/setting/enableReferral", { operatorId: this.props.admin.admin.operatorId, data: !this.state.referral }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
            .then(res => {
                this.setState({
                    ...this.state,
                    referral: res.data.data
                });
                this.props.enableReferral(res.data.data);
            })
            .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
    };

    handleCheckbox = (field, target) => {
        this.setState({
            ...this.state,
            [field]: {
                ...this.state[field],
                [target.id]: !this.state[field][target.id]
            }
        });
    };

    handleChange = (field, target) => {
        this.setState({
            ...this.state,
            [field]: {
                ...this.state[field],
                [target.id]: target.value
            }
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        const { operatorId } = this.props.admin.admin;
        const { voucher, stamp, token, numberOfReferral, amountTransaction } = this.state;

        const sendThis = {
            operatorId,
            voucher,
            stamp,
            token,
            numberOfReferral,
            amountTransaction
        };

        this.setState({
            ...this.state,
            loading: true
        });

        axios.post('/api/admin/setting/updateReferral', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
            .then(res => {
                this.setState({
                    ...this.state,
                    loading: false,
                })
                this.props.updateReferral(res.data.data);

                mySwal.fire({
                    title: "Success",
                    text: "Your referral setting has been updated successfully.",
                    icon: "success",
                    confirmButtonColor: "#ffd109"
                });
            })
            .catch(err => {
                mySwal.fire("Error", err.response.data.error, "error");
                this.setState({
                    ...this.state,
                    loading: false
                });
            });
    };

    render() {

        const stampSetting = () => {
            if (this.props.admin.admin.combined) {
                return (
                    <div className="form-group">
                        <label htmlFor="combined">Number of Stamp : </label>
                        <input type="number" id="combined" name="combined" value={this.state.stamp.combined || 0} onChange={e => this.handleChange("stamp", e.target)} required />
                    </div>
                );
            } else {
                if (this.props.admin.admin.specificStamp === "both") {
                    return (
                        <div>
                            <div className="form-group">
                                <label htmlFor="washer">Number of Washer Stamp: </label>
                                <input type="number" id="washer" name="washer" value={this.state.stamp.washer || 0} onChange={e => this.handleChange("stamp", e.target)} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="dryer">Number of Dryer Stamp: </label>
                                <input type="number" id="dryer" name="dryer" value={this.state.stamp.dryer || 0} onChange={e => this.handleChange("stamp", e.target)} required />
                            </div>
                        </div>
                    );
                } else if (this.props.admin.admin.specificStamp === "washerOnly") {
                    return (
                        <div className="form-group">
                            <label htmlFor="washer">Number of Washer Stamp: </label>
                            <input type="number" id="washer" name="washer" value={this.state.stamp.washer || 0} onChange={e => this.handleChange("stamp", e.target)} required />
                        </div>
                    );
                } else if (this.props.admin.admin.specificStamp === "dryerOnly") {
                    return (
                        <div className="form-group">
                            <label htmlFor="dryer">Number of Dryer Stamp: </label>
                            <input type="number" id="dryer" name="dryer" value={this.state.stamp.dryer || 0} onChange={e => this.handleChange("stamp", e.target)} required />
                        </div>
                    );
                }
            }
        }

        return (
            <div id="admin-dashboard" className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        <div className="mt-4 mb-5">
                            <div className="form-group">
                                <label>
                                    <input type="checkbox" id="enable" name="enable" checked={this.state.referral} onChange={this.handleEnable} />
                                    <span>Enable Referral Feature?</span>
                                </label>
                            </div>
                        </div>

                        {this.props.admin.admin && this.props.admin.admin.referral ? (
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Referral Setting</h5>
                                </div>

                                <form onSubmit={this.handleSubmit}>
                                    <div id="dashboard-content" className="card-body">
                                        <div className="container">
                                            <div className="form-group">
                                                <label htmlFor="numberOfReferral">Maximum number of referrals for each user : </label>
                                                <input type="number" id="numberOfReferral" name="numberOfReferral" value={this.state.numberOfReferral || 0} required
                                                    onChange={e => {
                                                        this.setState({
                                                            ...this.state,
                                                            numberOfReferral: e.target.value,
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="amountTransaction">Minimum amount of transaction : <small> (RM)</small></label>
                                                <input type="number" id="amountTransaction" name="amountTransaction" value={this.state.amountTransaction || 0} required
                                                    onChange={e => {
                                                        this.setState({
                                                            ...this.state,
                                                            amountTransaction: e.target.value,
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header">
                                                <h6 className="m-0 p-0">Stamp</h6>
                                            </div>

                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label htmlFor="stampEligible">
                                                        <input type="checkbox" id="stampEligible" name="stampEligible" checked={this.state.stamp.stampEligible || false} onChange={e => this.handleCheckbox("stamp", e.target)} />
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip>
                                                                    Give stamp to user if checked
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span>Eligibility</span>
                                                        </OverlayTrigger>
                                                    </label>
                                                </div>
                                                {
                                                    stampSetting()
                                                }
                                            </div>
                                        </div>

                                        <div className="card" style={{ width: "initial" }}>
                                            <div className="card-header">
                                                <h6 className="m-0 p-0">Voucher</h6>
                                            </div>

                                            <div className="card-body">
                                                <div>
                                                    <div>
                                                        {/* stamp voucher preview */}
                                                        <div className="voucher mb-5">
                                                            <div className="voucher-ball left-ball"></div>
                                                            <div className="voucher-head p-0 m-0" style={{ backgroundColor: selectedColor.headColor }}>
                                                                <h4 style={{ width: "140px", height: "140px", padding: "18px 0 0 0" }}>DISCOUNT</h4>
                                                            </div>

                                                            <div className="voucher-content" style={{ background: selectedColor.bodyColor }}>
                                                                <div>
                                                                    <h1>{this.state.voucher.type === "flat" ? (`RM${this.state.voucher.amount}`) : (`${this.state.voucher.amount}%`)}</h1>
                                                                </div>

                                                                <div>
                                                                    <h5>{this.state.voucher.title}</h5>
                                                                    <h6>{this.state.voucher.description}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="voucher-ball right-ball"></div>
                                                        </div>
                                                    </div>

                                                    <div className="text-center">
                                                        {/* checkboxes */}
                                                        <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                                            <div className="form-group">
                                                                <label htmlFor="voucherEligible">
                                                                    <input type="checkbox" id="voucherEligible" name="voucherEligible" checked={this.state.voucher.voucherEligible || false} onChange={e => this.handleCheckbox("voucher", e.target)} />
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip>
                                                                                Give voucher to user if checked
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>Eligibility</span>
                                                                    </OverlayTrigger>
                                                                </label>
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="expiry">
                                                                    <input type="checkbox" id="expiry" name="expiry" checked={this.state.voucher.expiry || false} onChange={e => this.handleCheckbox("voucher", e.target)} />
                                                                    <span>Expiry</span>
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                                            <div className="form-group">
                                                                <label>
                                                                    <input type="checkbox" id="washerUse" name="washerUse" checked={this.state.voucher.washerUse || false} onChange={e => this.handleCheckbox("voucher", e.target)} />
                                                                    <span>Washer Use</span>
                                                                </label>
                                                            </div>

                                                            <div className="form-group">
                                                                <label>
                                                                    <input type="checkbox" id="dryerUse" name="dryerUse" checked={this.state.voucher.dryerUse || false} onChange={e => this.handleCheckbox("voucher", e.target)} />
                                                                    <span>Dryer Use</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        {/* text and select */}
                                                        {
                                                            this.state.voucher.expiry
                                                                ? (
                                                                    <div className="form-group">
                                                                        <label htmlFor="duration">Voucher Lifespan : <small> (days)</small></label>
                                                                        <input type="number" id="duration" name="duration" value={this.state.voucher.duration || 0} onChange={e => this.handleChange("voucher", e.target)} required />
                                                                    </div>
                                                                )
                                                                : (
                                                                    null
                                                                )
                                                        }

                                                        <div className="form-group">
                                                            <label htmlFor="type">Discount Type : </label>
                                                            <select className="browser-default form-select" id="type" name="type" value={this.state.voucher.type || ""} onChange={e => this.handleChange("voucher", e.target)} required>
                                                                <option value="" disabled>Select a discount type</option>
                                                                <option value="flat">Flat (- RM)</option>
                                                                <option value="rate">Rate (- %)</option>
                                                            </select>
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="title">Voucher Title : </label>
                                                            <input type="text" id="title" name="title" placeholder="Voucher Title" value={this.state.voucher.title || ""} onChange={e => this.handleChange("voucher", e.target)} required />
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="description">Voucher Description : </label>
                                                            <input type="text" id="description" name="description" placeholder="Voucher Description" value={this.state.voucher.description || ""} onChange={e => this.handleChange("voucher", e.target)} required />
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="amount">Voucher Amount : <small>{this.state.voucher.type === "flat" ? "(RM)" : "(%)"}</small></label>
                                                            <input type="number" id="amount" name="amount" value={this.state.voucher.amount || 0} onChange={e => this.handleChange("voucher", e.target)} required />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {this.props.admin.admin.token ? (
                                            <div className="card">
                                                <div className="card-header">
                                                    <h6 className="m-0 p-0">Token</h6>
                                                </div>

                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <label htmlFor="tokenEligible">
                                                            <input type="checkbox" id="tokenEligible" name="tokenEligible" checked={this.state.token.tokenEligible || false} onChange={e => this.handleCheckbox("token", e.target)} />
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        Give token to user if checked
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>Eligibility</span>
                                                            </OverlayTrigger>
                                                        </label>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="total">Number of E-Token : </label>
                                                        <input type="number" id="total" name="total" value={this.state.token.total || 0} onChange={e => this.handleChange("token", e.target)} required />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null
                                        }

                                    </div>
                                    {
                                        this.state.loading ? <Loading /> : (
                                            <div className="mb-5 mx-5">
                                                <input type="submit" value="SUBMIT" className="btn big-button form-control" />
                                            </div>
                                        )
                                    }
                                </form>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        admin: state.admin
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableReferral: data => dispatch(enableReferral(data)),
        updateReferral: data => dispatch(updateReferral(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralSetting);
