import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ProgressBar } from 'react-bootstrap';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';

class PieTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: "Transactions"
        };
    };

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });
    };

    render() {

        let data = {
            columns: [],
            rows: []
        }

        if (this.state.filter === "Transactions") {
            data.columns = [
                {
                    label: "Outlets",
                    field: "outlets",
                    sort: "asc"
                },
                {
                    label: "Transactions",
                    field: "transactions",
                    sort: "asc"
                },
                {
                    label: "% Transactions",
                    field: "transactionsPercentage",
                    sort: "asc"
                }
            ]

            for (let i = 0; i < this.props.outlets.length; i++) {
                const numberOfTransaction = this.props.transactionData.filter(data => data.outlet === this.props.outlets[i].outletId).length;
                const dataObject = {
                    outlets: this.props.outlets[i].outletName,
                    transactions: numberOfTransaction,
                    transactionsPercentage: (
                        <div style={{ display: "flex", paddingLeft: "5px" }}>
                            <ProgressBar now={(numberOfTransaction / this.props.transactionData.length) * 100} />
                            <span className="px-2">{this.props.transactionData.length ? ((numberOfTransaction / this.props.transactionData.length) * 100).toFixed(2) : 0}%</span>
                        </div>
                    )
                }
                data.rows.push(dataObject);
            }

        } else if (this.state.filter === "E-Payment Sales") {
            data.columns = [
                {
                    label: "Outlets",
                    field: "outlets",
                    sort: "asc"
                },
                {
                    label: "E-Payment Sales",
                    field: "sales",
                    sort: "asc"
                },
                {
                    label: "% E-Payment Sales",
                    field: "salesPercentage",
                    sort: "asc"
                }
            ]

            for (let i = 0; i < this.props.outlets.length; i++) {
                const allTransactionsAmount = this.props.transactionData.map(transaction => transaction.payment.paymentAmount);
                const sumOfTransactionsAmount = allTransactionsAmount.reduce((a, b) => a + b, 0);

                const transaction = this.props.transactionData.filter(data => data.outlet === this.props.outlets[i].outletId);
                const transactionAmount = transaction.map(transaction => transaction.payment.paymentAmount);
                const transactionTotalAmount = transactionAmount.reduce((a, b) => a + b, 0);
                const dataObject = {
                    outlets: this.props.outlets[i].outletName,
                    sales: transactionTotalAmount,
                    salesPercentage: (
                        <div style={{ display: "flex", paddingLeft: "5px" }}>
                            <ProgressBar now={(transactionTotalAmount / sumOfTransactionsAmount) * 100} />
                            <span className="px-2">{sumOfTransactionsAmount ? ((transactionTotalAmount / sumOfTransactionsAmount) * 100).toFixed(2) : 0}%</span>
                        </div>
                    )
                }
                data.rows.push(dataObject);
            }
        }

        return (
            <div>
                <div className="pb-3">
                    <label htmlFor="filter">Filter: </label>
                    <select id="filter" name="filter" className="browser-default form-select" value={this.state.filter} onChange={this.handleChange}>
                        <option value="Transactions">Transactions</option>
                        <option value="E-Payment Sales">E-Payment Sales</option>
                    </select>
                </div>
                <div className="text-center mt-3">
                    <MDBTable scrollY striped hover bordered>
                        <MDBTableHead columns={data.columns} />
                        <MDBTableBody rows={data.rows} />
                    </MDBTable>
                </div>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        outlets: state.admin.admin.outlets
    };
};

export default connect(mapStateToProps, null)(PieTable);