import { Pie } from 'react-chartjs-2';

const StampChart = ({ transactionData }) => {

    let labels = ["Washer", "Dryer"];

    const numberOfWasher = transactionData.filter(data => data.payment.paymentAmount >= 5 && data.machine.type === "Washer").length;
    const numberOfDryer = transactionData.filter(data => data.payment.paymentAmount >= 5 && data.machine.type === "Dryer").length;

    let graphData = [numberOfWasher, numberOfDryer];

    const data = {
        labels: labels,
        datasets: [{
            data: graphData,
            backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
            ],
        }]
    };

    const options = {
        plugins: {
            title: {
                display: true,
                text: "Number of stamps granted to users"
            },
            subtitle: {
                display: true,
                text: `Total number of stamps granted: ${graphData.reduce((a, b) => a + b, 0)}`
            }
        }
    };

    return (<Pie data={data} options={options} />);
}

export default StampChart;