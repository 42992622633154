import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarContent } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { logo1Controller } from "../../../../dynamicController";

const Sidebar = ({ toggled, setToggled, themeLogo, distributor }) => {
	return (
		<ProSidebar breakPoint="xl" toggled={toggled} onToggle={val => setToggled(val)} id="react-pro-sidebar">
			<SidebarHeader id="react-pro-sidebar-header">
				<Link to="/distributor/dashboard">
					<img src={logo1Controller(themeLogo)} alt="cuci logo" className="img-fluid" />
				</Link>
			</SidebarHeader>

			<SidebarContent id="react-pro-sidebar-content">
				<Menu id="react-pro-sidebar-menu" iconShape="circle" popperArrow={true}>
					<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-home"></i>}>
						Dashboard <Link to="/distributor/dashboard" />
					</MenuItem>

					<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-store"></i>}>
						Store Performance <Link to="/distributor/outlet/performance" />
					</MenuItem>

					<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-users"></i>}>
						User Performance <Link to="/distributor/user/performance" />
					</MenuItem>

					<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-money-bill"></i>}>
						Voucher Redemption <Link to="/distributor/voucher/redemption" />
					</MenuItem>

					{distributor.token ? (
						<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-file-invoice-dollar"></i>}>
							Token Report <Link to="/distributor/token/report" />
						</MenuItem>
					) : (
						<></>
					)}

					<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-user-cog"></i>}>
						User Details <Link to="/distributor/user/details" />
					</MenuItem>

					{process.env.REACT_APP_STORETYPE !== "cuci" ? (
						<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="fas fa-envelope-open-text"></i>}>
							SMS Report <Link to="/distributor/sms/report" />
						</MenuItem>
					) : (
						<></>
					)}

				</Menu>
			</SidebarContent>
		</ProSidebar>
	);
};

const mapStateToProps = state => {
	return {
		themeLogo: state.theme.logo,
		distributor: state.distributor.distributor
	};
};

export default connect(mapStateToProps, null)(Sidebar);
