import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { adminCheckSession } from '../../../redux/actions/adminActions';
import Sidebar from '../components/Sidebar';
import Topbar from '../components/Topbar';
import Loading from '../components/Loading';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import VoucherGivenChart from './charts/VoucherGiven';
import StampChart from './charts/Stamps';
import UserByMonthChart from './charts/UserByMonth';
import TopUser from './charts/TopUser';
import TopOutlet from './charts/TopOutlet';
import SummaryData from './charts/SummaryData';
import PieTable from './charts/PieTable';

const mySwal = withReactContent(Swal);

class AdminDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            year: new Date(),
            loading: true,
            data: []
        };
    };

    componentDidMount() {
        if (this.props.admin.admin) {
            let sendThis = {
                operatorId: this.props.admin.admin.operatorId,
                year: this.state.year.getFullYear()
            };

            axios.post('/api/admin/report/dashboard', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        loading: false,
                        data: res.data
                    });
                })
                .catch(err => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    mySwal.fire("Error", err.response.data.error, "error");
                });
        } else {
            this.props.checkSession(() => {
                let sendThis = {
                    operatorId: this.props.admin.admin.operatorId,
                    year: this.state.year.getFullYear()
                };

                axios.post('/api/admin/report/dashboard', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                    .then(res => {
                        this.setState({
                            ...this.state,
                            loading: false,
                            data: res.data
                        });
                    })
                    .catch(err => {
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                        mySwal.fire("Error", err.response.data.error, "error");
                    });
            });
        }

    };

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleYear = date => {
        this.setState({
            ...this.state,
            year: date
        });
    };

    handleReset = e => {
        this.setState({
            ...this.state,
            year: new Date(),
            loading: true
        });

        let sendThis = {
            operatorId: this.props.admin.admin.operatorId,
            year: new Date().getFullYear()
        };

        axios.post('/api/admin/report/dashboard', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
            .then(res => {
                this.setState({
                    ...this.state,
                    loading: false,
                    data: res.data
                });
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                mySwal.fire("Error", err.response.data.error, "error");
            });
    };

    handleFilter = e => {
        this.setState({
            ...this.state,
            loading: true
        });

        let sendThis = {
            operatorId: this.props.admin.admin.operatorId,
            year: this.state.year.getFullYear()
        };

        axios.post('/api/admin/report/dashboard', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
            .then(res => {
                this.setState({
                    ...this.state,
                    loading: false,
                    data: res.data
                });
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                mySwal.fire("Error", err.response.data.error, "error");
            });
    };

    render() {

        if (!this.props.admin.isAuthenticated) {
            if (this.props.admin.isLoading) {
                return <Loading />
            } else {
                return (
                    <div className="text-center">
                        <h1 className="mt-5">Please <Link to="/admin/login">Login</Link> Again</h1>
                    </div>
                );
            }
        } else {
            return (
                <div id="admin-dashboard" className="admin-page-container">
                    <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />

                    <div className="admin-page">
                        <Topbar handleToggle={this.handleToggle} />

                        <div className="admin-content">
                            <div className="text-center">
                                <h3>Welcome</h3>
                            </div>

                            <div className="mt-5">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title">Dashboard</h5>
                                    </div>

                                    <div className="card-body">
                                        <div className="mb-5">
                                            <h6>Filter</h6>
                                            <div>
                                                <label htmlFor="year">Select Year: </label>
                                                <DatePicker
                                                    selected={this.state.year}
                                                    onChange={this.handleYear}
                                                    showYearPicker
                                                    dateFormat="yyyy"
                                                    yearItemNumber={9}
                                                />
                                            </div>
                                            <div className="d-flex">
                                                <button onClick={this.handleFilter} className="btn btn-primary btn-inline form-control me-3">Filter</button>
                                                <button onClick={this.handleReset} className="btn btn-secondary btn-inline form-control ms-3">Reset</button>
                                            </div>
                                        </div>

                                        <div>
                                            {
                                                this.state.loading ? <Loading /> : (
                                                    <div>
                                                        <div>
                                                            <SummaryData userData={this.state.data.users} voucherData={this.state.data.vouchers} transactionData={this.state.data.allTransactions} />
                                                        </div>
                                                        <div className="pt-5">
                                                            <UserByMonthChart userData={this.state.data.users} />
                                                        </div>
                                                        <div className="pt-5" style={{ display: "flex" }}>
                                                        <div style={{ width: this.props.admin.admin.combined ? "75%" : "50%" }}>
                                                            <PieTable transactionData={this.state.data.allTransactions} />
                                                        </div>
                                                            <div style={{ width: "25%" }}>
                                                                <VoucherGivenChart voucherData={this.state.data.vouchers} />
                                                            </div>
                                                            {
                                                                this.props.admin.admin.combined ? null : (
                                                                    <div style={{ width: "25%" }}>
                                                                        <StampChart transactionData={this.state.data.allTransactions} />
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        <h3 className="text-center pt-5">Ranking</h3>
                                                        <div style={{ display: "flex" }}>
                                                            <div style={{ width: "50%" }}>
                                                                <TopUser userData={this.state.data.users} transactionData={this.state.data.allTransactions} />
                                                            </div>
                                                            <div className="ps-5" style={{ width: "50%" }}>
                                                                <TopOutlet userData={this.state.data.users} transactionData={this.state.data.allTransactions} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };
};

const mapStateToProps = state => {
    return {
        admin: state.admin
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkSession: callback => dispatch(adminCheckSession(callback))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);