import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";

import Check from "./Check";
import Register from "./Register";
import Login from "./Login";

import { connect } from "react-redux";
import { /*changeLanguage, */checkToken, /*saveOperatorId,*/ } from "../../../redux/actions/userActions";
import { /*scannedCode, saveCreds,*/savePaymentProduct } from "../../../redux/actions/paymentActions";
//import { fetchTheme } from "../../../redux/actions/themeAction";

//import { logoController, smallTitle } from "../../../dynamicController";

import logo from "../../../assets/images/logo/logo.png"

//import Swal from 'sweetalert2';
//import withReactContent from 'sweetalert2-react-content';

//const mySwal = withReactContent(Swal);

class LandingPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			//showButton: false,
			loading: false
		};
	}

	_isMounted = false;

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			const { TID, Token } = queryString.parse(this.props.location.search);

			if (TID && Token) {
				this.setState({ ...this.state, loading: true });
				axios
					.post("/api/user/purchase/frame", { TID, Token })
					.then(res => {
						this._isMounted && this.props.savePaymentProduct(res.data.data);
						//this._isMounted && this.props.scanned(res.data.data);
						//this._isMounted && this.props.saveCredentials({ machineNo, outletCode, operatorCode: opId });
						this._isMounted &&
							this.setState({
								...this.satate,
								loading: false,
								//showButton: true
							});
						this._isMounted && this.props.checkToken();
					})
					.catch(err => {
						// mySwal.fire("Error" , err.response.data.error , "error");
						this._isMounted &&
							this.setState({
								...this.state,
								loading: false,
								//showButton: false
							});
					});
			} else {
				this._isMounted && this.props.checkToken();
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		const renderPages = () => {
			if (this.props.authProgress === "landing") {
				return <Check />;
			} else if (this.props.authProgress === "register") {
				return <Register query={this.props.location.search} />;
			} else if (this.props.authProgress === "login") {
				return <Login query={this.props.location.search} />;
			}
		};

		const registerClass = () => {
			if (this.props.authProgress === "register") {
				return "register";
			} else {
				return null;
			}
		};

		// const checkOperator = e => {

		// 	axios.post('/api/user/auth/checkDisable', { operatorId: this.props.operatorId })
		// 		.then(res => {
		// 			this.props.history.push("/faq");
		// 		})
		// 		.catch(err => {
		// 			mySwal.fire("Error", err.response.data.error, "error")
		// 		});
		// }

		// const handleChange = e => {
		// 	this.props.changeLanguage(e.target.value);
		// }

		const { TID, Token } = queryString.parse(this.props.location.search);

		if (this.props.isAuthenticated) {
			return <Redirect to={TID && Token ? `/user/payment${this.props.location.search}`: "/payment"} />;
		} else {
			return (
				<div id="auth-page">
					<div id="logo-container" className={registerClass()}>
						<img src={logo} alt="cuciExpress" className="img-fluid" />
						{/* <div className="language">
							<select name="language" id="language" className="browser-default form-select" value={this.props.language} onChange={handleChange} required>
								<option value="en">EN</option>
								<option value="ms">BM</option>
								<option value="zh">CH</option>
							</select>
						</div> */}
					</div>

					{/* {process.env.REACT_APP_STORETYPE !== "cuci" ? (
						<div className="text-center">
							<h4 style={{ color: "white" }}>{smallTitle()}</h4>
						</div>
					) : null} */}

					<div id="form-container" className={registerClass()}>
						{this.state.loading ? (
							<div className="text-center mt-5">
								<div
									style={{ width: "50px", height: "50px", fontWeight: "bold", fontSize: "26px", margin: "10px", padding: 0 }}
									className={process.env.REACT_APP_STORETYPE === "cuci" ? "mt-5 spinner-border text-warning" : "mt-5 spinner-border text-primary"}
								>
									<span className="sr-only">Loading...</span>
								</div>
							</div>
						) : (
							renderPages()
						)}
						{/* <i className="fas fa-question-circle fa-3x" id="faq-icon" onClick={checkOperator} /> */}
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.user.isAuthenticated,
		authProgress: state.user.authProgress,
		//themeLogo: state.theme.logo,
		//operatorId: state.user.operatorId,
		//language: state.user.language,
		//machine: state.payment.machine
	};
};

const mapDispatchToProps = dispatch => {
	return {
		checkToken: () => dispatch(checkToken()),
		//scanned: data => dispatch(scannedCode(data)),
		//fetchTheme: data => dispatch(fetchTheme(data)),
		//saveOperatorId: id => dispatch(saveOperatorId(id)),
		//saveCredentials: data => dispatch(saveCreds(data)),
		//changeLanguage: language => dispatch(changeLanguage(language)),
		savePaymentProduct: data => dispatch(savePaymentProduct(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
