const apiUrl = process.env.REACT_APP_API_URL;
const publicVapidKey = process.env.REACT_APP_PUBLIC_VAPID_KEY;

const urlBase64ToUint8Array = base64String => {
    // console.log("Running 'urlBase64ToUint8Array' function ...");
    // console.log({base64String});
    const padding = "=".repeat((4 - base64String.length % 4) % 4);
    // console.log({padding});

    const base64 = (base64String + padding).replace(/-/g , "+").replace(/_/g , "/");
    // console.log({base64});
    
    const rawData = window.atob(base64);
    // console.log({rawData});
    // console.log({rawLength : rawData.length});

    let outputArray = new Uint8Array(rawData.length);
    // console.log(outputArray);
    // console.log("type" , typeof outputArray);
    // console.log("length" , outputArray.length);

    for (let i = 0 ; i < rawData.length ; i ++) {
        // console.log({i , outputI : outputArray[i], charcode : rawData.charCodeAt(i)});
        outputArray[i] = rawData.charCodeAt(i);
    };
    // console.log("Converted");
    // console.log(outputArray);

    return outputArray;
};

const sendSubscription = (subscription, userId) => {
    const apiCall = `${apiUrl}/api/notifications/subscribe`;
    // console.log({apiCall});
    return fetch(apiCall , {
        method : "POST",
        body : JSON.stringify({subscription, userId}),
        headers : {
            "Content-Type" : "application/json"
        }
    });
    // .then(res => res.json())
    // .then(jsonRes => console.log({jsonRes}))
    // .catch(err => console.log({error : "Error from web-push :", err}));
};

const convertedVapidKey = urlBase64ToUint8Array(publicVapidKey);
// console.log({convertedVapidKey});

export const subscribeUser = userId => {
    if ('serviceWorker' in navigator) {
        // console.log("Service Worker found");
        navigator.serviceWorker.ready
        .then(registration => {
            // console.log({registration});
            if (!registration.pushManager) {
                console.log("Push manager not available");
                return;
            }

            registration.pushManager.getSubscription().then(existedSubscription => {
                console.log({existedSubscription});
                if (existedSubscription === null) {
                    console.log("No subscription detected, request for permisison");
                    registration.pushManager.subscribe({
                        applicationServerKey : convertedVapidKey,
                        userVisibleOnly : true
                    })
                    .then(newSubscription => {
                        console.log("Added new subscription");
                        console.log({newSubscription});
                        sendSubscription(newSubscription , userId);
                    })
                    .catch(err => {
                        console.error({err});
                        if (Notification.permission !== "granted") {
                            console.error("Permission was not granted");
                        } else {
                            console.error("Some error occured during subscription process");
                        }
                    });
                } else {
                    console.log("Existed subscription detected");
                    sendSubscription(existedSubscription , userId);
                }
            })
        })
        .catch(err => {
            // console.log("Some error occured during service worker registration");
            console.error({err});
        });
    }
};