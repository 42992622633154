import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { connect } from 'react-redux';
import { logoutUser } from '../../../redux/actions/userActions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import logo from "../../../assets/images/logo/logo.png";
//import { logoController } from '../../../dynamicController';

const mySwal = withReactContent(Swal);

const Sidebar = ({ toggled, setToggled, confirmLogout, /*themeLogo, operatorId, user*/ }) => {

    const { t } = useTranslation();

    const logout = () => {
        mySwal.fire({
            title: t("Confirmation"),
            text: t("Logout Session?"),
            icon: "question",
            showCancelButton: true,
            cancelButtonText: t("NO"),
            confirmButtonText: t("OK")
        })
            .then(result => {
                if (result.isConfirmed) {
                    confirmLogout();
                }
            })
    };

    // const share = () => {
    //     navigator.share({
    //         title: 'Referral Code Sharing',
    //         text: `Hi, you are invited to register an account with my referral code(${user.memberId}).`,
    //         url: `https://${window.location.hostname}/welcome?operatorCode=${operatorId}&referralCode=${user.memberId}`,
    //     })
    //         .then(() => console.log('Successful share'))
    //         .catch((error) => console.log('Error sharing', error));
    // }

    return (
        <ProSidebar breakPoint="xl" toggled={toggled} onToggle={val => setToggled(val)} id="react-pro-sidebar">
            <SidebarHeader id="react-pro-sidebar-header">
                <img src={logo} alt="main-logo" className="img-fluid px-3 py-3" />
            </SidebarHeader>

            <SidebarContent id="react-pro-sidebar-content">
                <Menu id="react-pro-sidebar-menu" iconShape="circle" popperArrow={true}>

                    <MenuItem id="account" className="react-pro-sidebar-menu-item" icon={<i className="fas fa-user"></i>}>
                        {t("Account")} <Link to="/user/setting" />
                    </MenuItem>

                    {/* <MenuItem id="faq" className="react-pro-sidebar-menu-item" icon={<i className="fas fa-question-circle"></i>}>
                        {t("FAQs")} 
                        <Link to="/faq" />
                    </MenuItem>

                    <MenuItem id="feedback" className="react-pro-sidebar-menu-item" icon={<i className="fas fa-comments"></i>}>
                        {t("Feedback")} 
                        <Link to="/user/feedback" />
                    </MenuItem> */}

                    {/* {
                        user.referral ? (
                            <MenuItem id="referFriends" className="react-pro-sidebar-menu-item" onClick={share} icon={<i className="fas fa-user-friends"></i>}>
                                {t("Refer Friends")}
                            </MenuItem>
                        ) : <></>
                    } */}

                </Menu>
            </SidebarContent>

            <SidebarFooter id="react-pro-sidebar-footer">
                <button id="logout" onClick={logout} className="btn big-button form-control">{t("LOGOUT")}</button>
            </SidebarFooter>
        </ProSidebar>
    );
};

// const mapStateToProps = state => {
//     return {
//         themeLogo: state.theme.logo,
//         operatorId: state.user.operatorId,
//         user: state.user.user
//     };
// };

const mapDispatchToProps = dispatch => {
    return {
        confirmLogout: () => dispatch(logoutUser())
    };
};

export default connect(null, mapDispatchToProps)(Sidebar);