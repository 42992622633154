import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import navStamp from '../../../assets/images/navIcons/navStamp.png';
import navCoupon from '../../../assets/images/navIcons/navCoupon.png';
import navTransaction from '../../../assets/images/navIcons/navTransaction.png';
import navNews from '../../../assets/images/navIcons/navNews.png';

import { userNavigatorClassNames, navCameraClassName } from '../../../dynamicController';
import { changeNav } from '../../../redux/actions/userActions';

const Navigator = ({ nav, changeNav }) => {

    const { t } = useTranslation();

    return (
        <div id="navigator">
            <div className="nav-separator">
                <div id="stamp" onClick={e => changeNav("stamp")} className={userNavigatorClassNames(nav, "stamp")}>
                    <img src={navStamp} alt="navStamp" className="img-fluid" />
                    <p>{t("Stamping")}</p>
                </div>

                <div id="coupon" /*onClick={e => changeNav("coupon")}*/ className={userNavigatorClassNames(nav, "coupon")}>
                    <img src={navCoupon} alt="navCoupon" className="img-fluid" />
                    <p>{t("Voucher")}</p>
                </div>
            </div>

            <div id="nav-camera-container" className="camera">
                <div onClick={e => changeNav("camera")} id="nav-camera" className={navCameraClassName}>
                    <i className="fas fa-camera"></i>
                </div>
            </div>

            <div className="nav-separator">
                <div id="transaction" onClick={e => changeNav("transaction")} className={userNavigatorClassNames(nav, "transaction")}>
                    <img src={navTransaction} alt="navTransaction" className="img-fluid" />
                    <p>{t("Transaction")}</p>
                </div>

                <div id="news" /*onClick={e => changeNav("news")}*/ className={userNavigatorClassNames(nav, "news")}>
                    <img src={navNews} alt="navNews" className="img-fluid" />
                    <p>{t("News")}</p>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        nav: state.user.nav
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeNav: nav => dispatch(changeNav(nav))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigator);