import AddDistributor from "./AddDistributor";
import Sidebar from "./Sidebar";
import { useState } from "react";

const AddDistributorPage = () => {
    const [show, setShow] = useState(false);

    return (
        <div className="dashboard-container">
            <div className="sidebar-container" id={show ? "show-sidebar" : ""}>
                <Sidebar setShow={setShow} />
            </div>
            <div className="content-container">
                <button className="btn btn-primary font-weight-bold menu-btn" onClick={() => setShow(true)}>Menu</button>
                <AddDistributor />
            </div>
        </div>
    );
};

export default AddDistributorPage;