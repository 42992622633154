import { useState, useEffect } from "react";
import { connect } from "react-redux";
import queryString from "query-string";

import Faq from "./components/Faq";
import News from "./components/News";

import { logoController } from "../../dynamicController";
import { fetchData } from "../../redux/actions/assistantAction";

const Assistant = ({ location, history, assistant, fetchInfo }) => {
	const [selected, setSelected] = useState("faq");

	useEffect(() => {
		const { outletCode } = queryString.parse(location.search);
		if (!outletCode) {
			history.push("/");
		} else {
			fetchInfo({ outletCode });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const selectColor = val => {
		if (val === selected) {
			return "selected-button";
		} else {
			return null;
		}
	};

	return (
		<div id="assistant-page">
			<div id="assistant-header">
				<img src={logoController()} alt="logo" className="img-fluid" />
			</div>

			<div id="assistant-body">
				{assistant.isLoading ? (
					<div className="text-center mt-5" style={{ width: "100%", height: "100%", margin: 0, padding: "5px 0" }}>
						<div style={{ width: "80px", height: "80px", fontWeight: "bold", fontSize: "36px", margin: "10px", padding: 0 }} className={process.env.REACT_APP_STORETYPE === "cuci" ? "mt-5 spinner-border text-warning" : "mt-5 spinner-border text-primary"}>
							<span className="sr-only">Loading...</span>
						</div>
					</div>
				) : (
					<div>
						<div className="px-3 mt-3">
							<div id="type-selector">
								<button onClick={e => setSelected("faq")} className={selectColor("faq")}>
									FAQs
								</button>
								<button onClick={e => setSelected("news")} className={selectColor("news")}>
									NEWS
								</button>
							</div>
						</div>

						<div className="mt-4 px-3">{selected === "faq" ? <Faq /> : <News />}</div>
					</div>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		assistant: state.assistant
	};
};

const mapDispatchToProps = dispatch => {
	return {
		fetchInfo: data => dispatch(fetchData(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Assistant);
