import React, { Component } from 'react';

import { connect } from 'react-redux';
import { /*changeCounter, */checkToken/*, grantVoucher*/ } from '../../../redux/actions/userActions';

import { Redirect } from 'react-router-dom';

import queryString from 'query-string';

import Navigator from '../utilities/Navigation';
import Dashboard from './Dashboard';
import Coupon from './Coupon';
import Transaction from './Transaction';
import News from './News';
import Camera from './Camera';

//import giftGif from '../../../assets/gif/giftGif.gif';

//import Swal from 'sweetalert2';
//import withReactContent from 'sweetalert2-react-content';
import i18n from "i18next";

import { subscribeUser } from '../../../subscription';

//const mySwal = withReactContent(Swal);

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    };

    _isMounted = false;

    componentDidMount = () => {
        this._isMounted = true;

        if (this._isMounted) {

            this.props.checkToken();

            if (this.props.isAuthenticated) {
                subscribeUser(this.props.user._id);
                // const { combinedSetting, combinedStamps, dryerStamp, washerStamp } = this.props.user;
                // const id = this.props.user._id;
                // // console.log({combinedSetting, combinedStamps, dryerStamp, washerStamp, id});
                // let sendThis = { id };

                // if (this.props.user.referral) {
                //     if (this.props.modalCounter < 3) {
                //         let counter = this.props.modalCounter;
                //         counter ++;
                //         this.props.changeCounter(counter);
                //         mySwal.fire({
                //             title: i18n.t("Inviting friends to get more benefits!"),
                //             text: i18n.t("Are you want to share your referral code to your friends now?"),
                //             icon: "question",
                //             showCancelButton: true,
                //             cancelButtonText: i18n.t("No"),
                //             confirmButtonText: i18n.t("Yes"),
                //             customClass: {
                //                 confirmButton: 'share-referral-yes',
                //                 cancelButton: 'share-referral-no'
                //             },
                //         }).then(click => {
                //             if (click.isConfirmed && click.value) {
                //                 navigator.share({
                //                     title: 'Referral Code Sharing',
                //                     text: `Hi, you are invited to register an account with my referral code(${this.props.user.memberId}).`,
                //                     url: `https://${window.location.hostname}/welcome?operatorCode=${this.props.operatorId}&referralCode=${this.props.user.memberId}`,
                //                 })
                //                     .then(() => console.log('Successful share'))
                //                     .catch((error) => console.log('Error sharing', error));
                //             }
                //         })
                //     }
                // }

                // if (combinedSetting && combinedStamps >= 10) {
                //     // check combinedStamps
                //     sendThis.target = "combined";
                //     mySwal.fire({
                //         title: i18n.t("Congratulations!"),
                //         html: i18n.t("You have completed your Stamp collection.<br />Granted a voucher and stamp collection is refreshed"),
                //         imageUrl: giftGif,
                //         imageAlt: "Gift Gif",
                //         background: "#fbfbfb"
                //     });
                //     this.props.grantVoucher(sendThis);
                // } else {
                //     // check washerStamp and dryerStamp
                //     if (washerStamp >= 10) {
                //         // give washer voucher
                //         sendThis.target = "washer";
                //         mySwal.fire({
                //             title: i18n.t("Congratulations!"),
                //             html: i18n.t("You have completed Washer Stamp collection.<br />Granted a voucher and stamp collection is refreshed"),
                //             imageUrl: giftGif,
                //             imageAlt: "Gift Gif",
                //             background: "#fbfbfb"
                //         });
                //         this.props.grantVoucher(sendThis);
                //     } else if (dryerStamp >= 10) {
                //         // give dryer voucher
                //         sendThis.target = "dryer";
                //         mySwal.fire({
                //             title: i18n.t("Congratulations!"),
                //             html: i18n.t("You have completed Dryer Stamp collection.<br />Granted a voucher and stamp collection is refreshed"),
                //             imageUrl: giftGif,
                //             imageAlt: "Gift Gif",
                //             background: "#fbfbfb"
                //         });
                //         this.props.grantVoucher(sendThis);
                //     }
                // }
            }
        }
    };

    componentWillUnmount = () => this._isMounted = false;

    render() {
        const renderPage = () => {
            if (this.props.nav === "stamp") {
                return <Dashboard history={this.props.history} />
            } else if (this.props.nav === "coupon") {
                return <Coupon />
            } else if (this.props.nav === "transaction") {
                return <Transaction />
            } else if (this.props.nav === "news") {
                return <News />
            } else if (this.props.nav === "camera") {
                return <Camera history={this.props.history} />
            } else {
                return (
                    <div className="text-center">
                        <h1>{i18n.t("Something went wrong")} :/</h1>
                    </div>
                )
            }
        };

        const { TID, Token } = queryString.parse(this.props.location.search);

        if (!this.props.isAuthenticated) {
            return <Redirect to={`/welcome${TID && Token ? (this.props.location.search) : ("")}`} />
        } else {
            return (
                <div>
                    {renderPage()}
                    <Navigator />
                </div>
            );
        }
    };
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.user.isAuthenticated,
        user: state.user.user,
        //operatorId: state.user.operatorId,
        nav: state.user.nav,
        //modalCounter: state.user.modalCounter
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkToken: () => dispatch(checkToken()),
        //grantVoucher: data => dispatch(grantVoucher(data)),
        //changeCounter: data => dispatch(changeCounter(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);