import React, { lazy, Suspense } from "react";

const CuciTheme = lazy(() => import("./cuciTheme"));
const LaundroTheme = lazy(() => import("./laundroTheme"));

const ThemeController = ({ children }) => {
	return (
		<>
			<Suspense fallback={<></>}>
				{
					process.env.REACT_APP_STORETYPE === "cuci"
					? <CuciTheme />
					: <LaundroTheme />
				}
			</Suspense>
			{children}
		</>
	);
};

export default ThemeController;
