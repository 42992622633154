import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CardGroup, Card } from 'react-bootstrap';
import i18n from "i18next";

import birthdayBanner from "../../../../assets/images/news/birthdayBanner.jpg";
import newUserBanner from "../../../../assets/images/news/newUserBanner.jpg";
import referralBanner from "../../../../assets/images/news/referralBanner.jpg";
import stampBanner from "../../../../assets/images/news/stampBanner.jpg";
import tokenBanner from "../../../../assets/images/news/tokenBanner.jpg";

const mySwal = withReactContent(Swal);

class NewsSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            news: [],
        };
    };

    componentDidMount() {
        if (this.props.operatorId) {
            axios
                .post("/api/user/getVoucherSetting", { operatorId: this.props.operatorId })
                .then(res => {
                    this.setState({
                        ...this.state,
                        data: res.data,
                    });

                    axios
                        .post("/api/user/getNews", { operatorId: this.props.operatorId })
                        .then(res => {
                            this.setState({
                                ...this.state,
                                news: res.data,
                                loading: false
                            });
                        })
                        .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
                })
                .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
        }
    }

    render() {

        const renderStampBanner = () => {
            if (this.props.combinedSetting) {
                if (this.state.data[0].stamp.combined.eligible) {
                    return (
                        <Card className={this.props.page === "dashboard" ? "newsCard" : null} onClick={() => this.props.history.push('/user/news/stamp')}>
                            <Card.Img variant="top" src={stampBanner} />
                            <Card.Body style={{ padding: "0.5rem 1rem" }}>
                                <Card.Text>{i18n.t("Learn More")}</Card.Text>
                            </Card.Body>
                        </Card>
                    );
                }
            } else {
                if (this.state.data[0].stamp.washer.eligible || this.state.data[0].stamp.dryer.eligible) {
                    return (
                        <Card className={this.props.page === "dashboard" ? "newsCard" : null} onClick={() => this.props.history.push('/user/news/stamp')}>
                            <Card.Img variant="top" src={stampBanner} />
                            <Card.Body style={{ padding: "0.5rem 1rem" }}>
                                <Card.Text>{i18n.t("Learn More")}</Card.Text>
                            </Card.Body>
                        </Card>
                    );
                }
            }
        }

        const renderReferralBanner = () => {
            if (this.props.referral) {
                if (this.state.data[1].stamp.stampEligible || this.state.data[1].token.tokenEligible || this.state.data[1].voucher.voucherEligible) {
                    return (
                        <Card className={this.props.page === "dashboard" ? "newsCard" : null} onClick={() => this.props.history.push('/user/news/referral')}>
                            <Card.Img variant="top" src={referralBanner} />
                            <Card.Body style={{ padding: "0.5rem 1rem" }}>
                                <Card.Text>{i18n.t("Learn More")}</Card.Text>
                            </Card.Body>
                        </Card>
                    );
                }
            }
        }

        const renderList = () => {

            if (this.state.loading) {
                return (
                    <div className="text-center">
                        <div className="spinner-border" style={{ color: "#ffd109" }} role="status">
                            <span className="sr-only">Loading ...</span>
                        </div>
                    </div>
                );
            } else {
                return (
                    <CardGroup className={this.props.page === "dashboard" ? "newsSlider" : null}>
                        {this.state.data[0].register.combined.eligible ? (
                            <Card className={this.props.page === "dashboard" ? "newsCard" : null} onClick={() => this.props.history.push('/user/news/newUser')}>
                                <Card.Img variant="top" src={newUserBanner} />
                                <Card.Body style={{ padding: "0.5rem 1rem" }}>
                                    <Card.Text>{i18n.t("Learn More")}</Card.Text>
                                </Card.Body>
                            </Card>
                        ) : null}
                        {
                            renderReferralBanner()
                        }
                        {this.state.data[0].birthday.combined.eligible ? (
                            <Card className={this.props.page === "dashboard" ? "newsCard" : null} onClick={() => this.props.history.push('/user/news/birthday')}>
                                <Card.Img variant="top" src={birthdayBanner} />
                                <Card.Body style={{ padding: "0.5rem 1rem" }}>
                                    <Card.Text>{i18n.t("Learn More")}</Card.Text>
                                </Card.Body>
                            </Card>
                        ) : null}
                        {
                            renderStampBanner()
                        }
                        {this.props.token ? (
                            <Card className={this.props.page === "dashboard" ? "newsCard" : null} onClick={() => this.props.history.push('/user/news/token')}>
                                <Card.Img variant="top" src={tokenBanner} />
                                <Card.Body style={{ padding: "0.5rem 1rem" }}>
                                    <Card.Text>{i18n.t("Learn More")}</Card.Text>
                                </Card.Body>
                            </Card>
                        ) : null}
                        {
                            this.state.news.map((news, i) => {
                                if (news.endDate) {
                                    if (new Date(news.endDate) >= new Date()) {
                                        return (
                                            <Card className={this.props.page === "dashboard" ? "newsCard" : null} onClick={() => this.props.history.push(`/user/news/${news._id}`)} key={i}>
                                                <Card.Img variant="top" src={news.banner} />
                                                <Card.Body style={{ padding: "0.5rem 1rem" }}>
                                                    <Card.Text>{i18n.t("Learn More")}</Card.Text>
                                                </Card.Body>
                                            </Card>
                                        );
                                    }
                                } else {
                                    return (
                                        <Card className={this.props.page === "dashboard" ? "newsCard" : null} onClick={() => this.props.history.push(`/user/news/${news._id}`)} key={i}>
                                            <Card.Img variant="top" src={news.banner} />
                                            <Card.Body style={{ padding: "0.5rem 1rem" }}>
                                                <Card.Text>{i18n.t("Learn More")}</Card.Text>
                                            </Card.Body>
                                        </Card>
                                    );
                                }
                            })
                        }
                    </CardGroup>
                );
            }
        }

        return (
            <div>
                {
                    renderList()
                }
            </div>
        );

    };
};

const mapStateToProps = state => {
    return {
        operatorId: state.user.operatorId,
        token: state.user.user.tokenSetting,
        combinedSetting: state.user.user.combinedSetting,
        referral: state.user.user.referral
    };
};

export default connect(mapStateToProps, null)(NewsSlider);