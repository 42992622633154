import { useState, useEffect } from "react";

import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

import Sidebar from "../utilities/Sidebar";

const colorSets = [
	{
		headColor: "#c596c4",
		bodyColor: "linear-gradient(135deg , #c8bfde , #a1c3e9)"
	},
	{
		headColor: "#fbcc7d",
		bodyColor: "linear-gradient(135deg , #f6b997 , #f38695)"
	},
	{
		headColor: "#93c9ed",
		bodyColor: "linear-gradient(135deg , #6599d3 , #77cebb)"
	},
	{
		headColor: "#c7a1aa",
		bodyColor: "linear-gradient(135deg , #f2d0e0 , #c6a9d2)"
	},
	{
		headColor: "#b6da98",
		bodyColor: "linear-gradient(135deg , #cee6a9 , #dbde91)"
	}
];

let index = 0;

const Coupon = ({ user }) => {
	const [toggled, setToggled] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		index = 0;

		return function cleanup() {
			index = 0;
		};
	}, []);

	const renderCoupons = () => {
		if (user) {
			return user.vouchers
				.filter(voucher => voucher.available)
				.map(voucher => {
					const randomNumber = index; /*Math.floor(Math.random() * ((colorSets.length - 1) + 1));*/

					if (index === colorSets.length - 1) {
						index = 0;
					} else {
						index += 1;
					}

					return (
						<div className="voucher mb-3" key={voucher._id}>
							<div className="voucher-ball left-ball"></div>
							<div className="voucher-head" style={{ backgroundColor: colorSets[randomNumber].headColor }}>
								<h4>{t("DISCOUNT")}</h4>
							</div>

							<div className="voucher-content" style={{ background: colorSets[randomNumber].bodyColor }}>
								<div>
									<h1>{voucher.type === "flat" ? `RM${voucher.amount}` : `${voucher.amount}%`}</h1>
								</div>

								<div>
									<h5>{voucher.title}</h5>
									<h6 className="m-0 p-0">{voucher.description}</h6>
									{voucher.expiredDate ? (
										<h6 style={{ color: "red", textAlign: "right" }} className="m-0 p-0">
											<small>{t("Expired Date")}: {`${new Date(voucher.expiredDate).getDate()}/${new Date(voucher.expiredDate).getMonth()+1}/${new Date(voucher.expiredDate).getFullYear()}`}</small>
										</h6>
									) : null}
								</div>
							</div>
							<div className="voucher-ball right-ball"></div>
						</div>
					);
				});
		}
	};

	return (
		<div id="coupon-page">
			<Sidebar toggled={toggled} setToggled={setToggled} />
			<div id="user-header">
				<h5>
					<i onClick={e => setToggled(true)} className="fas fa-bars me-3"></i> <strong>{t("Voucher")}</strong>
				</h5>
			</div>

			<div id="coupon-body">{renderCoupons()}</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.user.user
	};
};

export default connect(mapStateToProps, null)(Coupon);
