import { IS_LOADING, IS_LOADED, SHOW_ERROR, CLEAR_ERROR, REMOVE_TOKEN, ADMIN_LOGIN, UPDATE_THEME, UPDATE_VOUCHER, UPDATE_MECHANIC, ADD_PACKAGE, DEL_PACKAGE, ENABLE_TOKEN, ENABLE_REFERRAL, UPDATE_REFERRAL } from "./type";
import axios from "axios";

export const adminLogin = data => dispatch => {
	dispatch({ type: CLEAR_ERROR });
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/auth/login", data)
		.then(res => {
			dispatch({ type: ADMIN_LOGIN, payload: res.data.data });
			dispatch({ type: IS_LOADED });
		})
		.catch(err => {
			dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
			dispatch({ type: IS_LOADED });
		});
};

export const adminCheckSession = callback => (dispatch, getState) => {
	if (getState().admin.token && !getState().admin.isAuthenticated) {
		dispatch({ type: IS_LOADING });
		axios
			.post("/api/admin/auth/checkToken", { token: getState().admin.token })
			.then(res => {
				dispatch({ type: ADMIN_LOGIN, payload: res.data.data });
				dispatch({ type: IS_LOADED });
				callback();
			})
			.catch(err => {
				console.log(err);
				dispatch({ type: REMOVE_TOKEN });
				dispatch({ type: IS_LOADED });
			});
	}
};

export const adminTokenLogin = token => dispatch => {
	axios
		.post("/api/admin/auth/tokenLogin", { token })
		.then(res => dispatch({ type: ADMIN_LOGIN, payload: res.data.data }))
		.catch(err => {
			console.log(err);
			dispatch({ type: REMOVE_TOKEN });
		});
};

export const updateTheme = theme => {
	return {
		type: UPDATE_THEME,
		payload: theme
	};
};

export const adminLogout = () => {
	return {
		type: REMOVE_TOKEN
	};
};

export const updateVoucher = data => {
	return {
		type: UPDATE_VOUCHER,
		payload: data
	};
};

export const updateMechanic = data => {
	return {
		type: UPDATE_MECHANIC,
		payload: data
	};
};

export const enableToken = data => {
	return {
		type: ENABLE_TOKEN,
		payload: data
	};
};

export const addPackage = data => {
	return {
		type: ADD_PACKAGE,
		payload: data
	};
};

export const delPackage = data => {
	return {
		type: DEL_PACKAGE,
		payload: data
	};
};

export const enableReferral = data => {
	return {
		type: ENABLE_REFERRAL,
		payload: data
	};
};

export const updateReferral = data => {
	return {
		type: UPDATE_REFERRAL,
		payload: data
	};
};