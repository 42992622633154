import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBDataTable } from "mdbreact";

class TopOutlet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: "Transactions"
        };
    };

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });
    };

    render() {

        let data = {
            columns: [
                {
                    label: "Top Outlets",
                    field: "topOutlets",
                    sort: "asc"
                },
                {
                    label: "Users",
                    field: "users",
                    sort: "asc"
                },
                {
                    label: "Transactions",
                    field: "transactions",
                    sort: "asc"
                }
            ],
            rows: []
        }

        let ranking = [];
     
        for(let i = 0; i < this.props.outlets.length; i++) {
            const transaction = this.props.transactionData.filter(data => data.outlet === this.props.outlets[i].outletId).length;
            const user = this.props.userData.filter(data => data.outletCode === this.props.outlets[i].outletId).length;

            ranking.push([this.props.outlets[i].outletName, user, transaction]);            
        }

        if (this.state.filter === "Users") {

            ranking.sort(function (a, b) {
                return b[1] - a[1];
            });

        } else if (this.state.filter === "Transactions") {

            ranking.sort(function (a, b) {
                return b[2] - a[2];
            });
        }

        for (let i = 0; i < 5; i++) {
            if (ranking[i]) {
                const dataObject = {
                    topOutlets: ranking[i][0],
                    users: ranking[i][1],
                    transactions: ranking[i][2]
                };
                data.rows.push(dataObject);
            }
        }

        return (
            <div>
                <div>
                    <h6 className="text-center">Top Outlets</h6>
                    <div>
                        <label htmlFor="filter">Sort by: </label>
                        <select id="filter" name="filter" className="browser-default form-select" value={this.state.filter} onChange={this.handleChange}>
                            <option value="Transactions">Transactions</option>
                            <option value="Users">Users</option>
                        </select>
                    </div>
                </div>

                <div className="text-center mt-3"><MDBDataTable data={data} paging={false} searching={false} noBottomColumns striped hover responsive bordered /></div>

            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        outlets: state.admin.admin.outlets
    };
};

export default connect(mapStateToProps, null)(TopOutlet);