const TermsConditions = ({ history }) => {

    return (
        <div id="agreement-page">
            <nav id="agreement-nav">
                <button onClick={e => history.goBack()} style={{outline: "none", backgroundColor: "transparent", border: "none"}}><i className="fas fa-arrow-left"></i></button>
            </nav>

            <div className="text-center mt-4 mb-5 px-5">
                <h3>Cuci Express Loyalty Terms and Conditions</h3>
                <div className="text-justify">
                    <p><small>Last updated: 31 March 2021</small></p>

                    <div className="mt-5">
                        <h5 className="text-left">AGREEMENT TO TERMS</h5>
                        <p>These Terms and Conditions constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and Klean And Shine Sdn. Bhd. (“we,” “us” or “our”), concerning your access to and use of the Cuci Express Loyalty Web application as well as any other media form, media channel, mobile website or web application related, linked, or otherwise connected thereto (collectively, the “App”).</p>
                        <p>You agree that by accessing the App, you have read, understood, and agree to be bound by all of these Terms and Conditions. If you do not agree with all of these Terms and Conditions, then you are expressly prohibited from using the App and you must discontinue use immediately.</p>
                        <p>Supplemental terms and conditions or documents that may be posted on the App from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms and Conditions at any time and for any reason.</p>
                        <p>We will alert you about any changes by updating the “Last updated” date of these Terms and Conditions, and you waive any right to receive specific notice of each such change.</p>
                        <p>It is your responsibility to periodically review these Terms and Conditions to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms and Conditions by your continued use of the App after the date such revised Terms and Conditions are posted.</p>
                        <p>The information provided on the App is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country.</p>
                        <p>Accordingly, those persons who choose to access the App from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left">INTELLECTUAL PROPERTY RIGHTS</h5>
                        <p>Unless otherwise indicated, the App is our proprietary property and all databases, software, website designs, audio, video, text, photographs, and graphics on the App (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights.</p>
                        <p>The Content and the Marks are provided on the App “AS IS” for your information and personal use only. Except as expressly provided in these Terms and Conditions, no part of the App and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.</p>
                        <p>Provided that you are eligible to use the App, you are granted a limited license to access and use the App and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the App, the Content and the Marks.</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left">USER REPRESENTATIONS</h5>
                        <p>By using the App, you represent and warrant that:</p>
                        <p>(1) all registration information you submit will be true, accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly update such registration information as necessary;</p>
                        <p>(2) you have the legal capacity and you agree to comply with these Terms and Conditions;</p>
                        <p>(3) you will not access the App through automated or non-human means, whether through a bot, script, or otherwise;</p>
                        <p>(4) you will not use the App for any illegal or unauthorized purpose;</p>
                        <p>(5) your use of the App will not violate any applicable law or regulation.</p>
                        <p>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the App (or any portion thereof).</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left">USER REGISTRATION</h5>
                        <p>You may be required to register with the App. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left">PROHIBITED ACTIVITIES</h5>
                        <p>You may not access or use the App for any purpose other than that for which we make the App available. The App may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.</p>
                        <p>As a user of the App, you agree not to:</p>
                        <ol>
                            <li>systematically retrieve data or other content from the App to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</li>
                            <li>make any unauthorized use of the App, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.</li>
                            <li>use a buying agent or purchasing agent to make purchases on the App.</li>
                            <li>use the App to advertise or offer to sell goods and services.</li>
                            <li>circumvent, disable, or otherwise interfere with security-related features of the App, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the App and/or the Content contained therein.</li>
                            <li>engage in unauthorized framing of or linking to the App.</li>
                            <li>trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords;</li>
                            <li>make improper use of our support services or submit false reports of abuse or misconduct.</li>
                            <li>engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</li>
                            <li>interfere with, disrupt, or create an undue burden on the App or the networks or services connected to the App.</li>
                            <li>attempt to impersonate another user or person or use the username of another user.</li>
                            <li>sell or otherwise transfer your profile.</li>
                            <li>use the App as part of any effort to compete with us or otherwise use the App and/or the Content for any revenue-generating endeavor or commercial enterprise.</li>
                            <li>decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the App.</li>
                            <li>attempt to bypass any measures of the App designed to prevent or restrict access to the App, or any portion of the App.</li>
                            <li>harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the App to you.</li>
                            <li>delete the copyright or other proprietary rights notice from any Content.</li>
                            <li>copy or adapt the App’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</li>
                            <li>upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the App or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the App.</li>
                            <li>upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as “spyware” or “passive collection mechanisms” or “pcms”).</li>
                            <li>disparage, tarnish, or otherwise harm, in our opinion, us and/or the App.</li>
                            <li>use the App in a manner inconsistent with any applicable laws or regulations.</li>
                        </ol>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left">CONTRIBUTION LICENSE</h5>
                        <p>By posting your Contributions to any part of the App, you automatically grant, and you represent and warrant that you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to host, use, copy, reproduce, disclose, sell, resell, publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat, translate, transmit, excerpt (in whole or in part), and distribute such Contributions (including, without limitation, your image and voice) for any purpose, commercial, advertising, or otherwise, and to prepare derivative works of, or incorporate into other works, such Contributions, and grant and authorize sublicenses of the foregoing. The use and distribution may occur in any media formats and through any media channels.</p>
                        <p>This license will apply to any form, media, or technology now known or hereafter developed, and includes our use of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names, logos, and personal and commercial images you provide. You waive all moral rights in your Contributions, and you warrant that moral rights have not otherwise been asserted in your Contributions.</p>
                        <p>We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for any statements or representations in your Contributions provided by you in any area on the App.</p>
                        <p>You are solely responsible for your Contributions to the App and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.</p>
                        <p>We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any Contributions; (2) to re-categorize any Contributions to place them in more appropriate locations on the App; and (3) to pre-screen or delete any Contributions at any time and for any reason, without notice. We have no obligation to monitor your Contributions.</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left">GUIDELINES FOR REVIEWS</h5>
                        <p>We may provide you areas on the App to leave reviews or ratings. When posting a review, you must comply with the following criteria:</p>
                        <p>(1) you should have firsthand experience with the person/entity being reviewed;</p>
                        <p>(2) your reviews should not contain offensive profanity, or abusive, racist, offensive, or hate language;</p>
                        <p>(3) your reviews should not contain discriminatory references based on religion, race, gender, national origin, age, marital status, sexual orientation, or disability;</p>
                        <p>(4) your reviews should not contain references to illegal activity;</p>
                        <p>(5) you should not be affiliated with competitors if posting negative reviews;</p>
                        <p>(6) you should not make any conclusions as to the legality of conduct;</p>
                        <p>(7) you may not post any false or misleading statements;</p>
                        <p>(8) you may not organize a campaign encouraging others to post reviews, whether positive or negative.</p>
                        <p>We may accept, reject, or remove reviews in our sole discretion. We have absolutely no obligation to screen reviews or to delete reviews, even if anyone considers reviews objectionable or inaccurate. Reviews are not endorsed by us, and do not necessarily represent our opinions or the views of any of our affiliates or partners.</p>
                        <p>We do not assume liability for any review or for any claims, liabilities, or losses resulting from any review. By posting a review, you hereby grant to us a perpetual, non-exclusive, worldwide, royalty-free, fully-paid, assignable, and sublicensable right and license to reproduce, modify, translate, transmit by any means, display, perform, and/or distribute all content relating to reviews.</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left">WEB APPLICATION LICENSE</h5>
                        <h6>Use License</h6>
                        <p>If you access the App via a web application, then we grant you a revocable, non-exclusive, non-transferable, limited right to install and use the web application on wireless electronic devices owned or controlled by you, and to access and use the web application on such devices strictly in accordance with the terms and conditions of this web application license contained in these Terms and Conditions.</p>
                        <p>You shall not:</p>
                        <p>(1) decompile, reverse engineer, disassemble, attempt to derive the source code of, or decrypt the application;</p>
                        <p>(2) make any modification, adaptation, improvement, enhancement, translation, or derivative work from the application;</p>
                        <p>(3) violate any applicable laws, rules, or regulations in connection with your access or use of the application;</p>
                        <p>(4) remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark) posted by us or the licensors of the application;</p>
                        <p>(5) use the application for any revenue generating endeavor, commercial enterprise, or other purpose for which it is not designed or intended;</p>
                        <p>(6) make the application available over a network or other environmental permitting access or use by multiple devices or users at the same time;</p>
                        <p>(7) use the application for creating a product, service, or software that is, directly or indirectly, competitive with or in any way a substitute for the application;</p>
                        <p>(8) use the application to send automated queries to any website or to send any unsolicited commercial e-mail;</p>
                        <p>(9) use any proprietary information or any of our interfaces or our other intellectual property in the design, development, manufacture, licensing, or distribution of any applications, accessories, or devices for use with the application.</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left">SUBMISSIONS</h5>

                        <p>You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information regarding the App (“Submissions”) provided by you to us are non-confidential and shall become our sole property. We shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of these Submissions for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.</p>
                        <p>You hereby waive all moral rights to any such Submissions, and you hereby warrant that any such Submissions are original with you or that you have the right to submit such Submissions. You agree there shall be no recourse against us for any alleged or actual infringement or misappropriation of any proprietary right in your Submissions.</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left">APPLICATION MANAGEMENT</h5>
                        <p>We reserve the right, but not the obligation, to:</p>
                        <p>(1) monitor the App for violations of these Terms and Conditions;</p>
                        <p>(2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms and Conditions, including without limitation, reporting such user to law enforcement authorities;</p>
                        <p>(3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof;</p>
                        <p>(4) in our sole discretion and without limitation, notice, or liability, to remove from the App or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems;</p>
                        <p>(5) otherwise manage the App in a manner designed to protect our rights and property and to facilitate the proper functioning of the App.</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left">PRIVACY POLICY</h5>
                        <p>We care about data privacy and security. Please review our Privacy Policy posted on the App. By using the App, you agree to be bound by our Privacy Policy, which is incorporated into these Terms and Conditions.</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left">COPYRIGHT INFRINGEMENTS</h5>
                        <p>We respect the intellectual property rights of others. If you believe that any material available on or through the App infringes upon any copyright you own or control, please immediately notify us using the contact information provided below (a “Notification”). A copy of your Notification will be sent to the person who posted or stored the material addressed in the Notification.</p>
                        <p>Please be advised that pursuant to federal law you may be held liable for damages if you make material misrepresentations in a Notification. Thus, if you are not sure that material located on or linked to by the App infringes your copyright, you should consider first contacting an attorney.]</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left">TERM AND TERMINATION</h5>
                        <p>These Terms and Conditions shall remain in full force and effect while you use the App. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS AND CONDITIONS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF the App (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS AND CONDITIONS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN the App OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.</p>
                        <p>If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party.</p>
                        <p>In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left">MODIFICATIONS AND INTERRUPTIONS</h5>
                        <p>We reserve the right to change, modify, or remove the contents of the App at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Site. We also reserve the right to modify or discontinue all or part of the App without notice at any time.</p>
                        <p>We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the App.</p>
                        <p>We cannot guarantee the App will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the App, resulting in interruptions, delays, or errors.</p>
                        <p>We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the App at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the App during any downtime or discontinuance of the App.</p>
                        <p>Nothing in these Terms and Conditions will be construed to obligate us to maintain and support the App or to supply any corrections, updates, or releases in connection therewith.</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left">CORRECTIONS</h5>
                        <p>There may be information on the App that contains typographical errors, inaccuracies, or omissions that may relate to the App, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the App at any time, without prior notice.</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left">DISCLAIMER</h5>
                        <p>The App IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF The App AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH the App AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF the App’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO the App AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF the App, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM the App, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH the App BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA the App. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH the App, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR WEB APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.</p>
                        <p>AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left">LIMITATIONS OF LIABILITY</h5>
                        <p>IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF the App, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left">INDEMNIFICATION</h5>
                        <p>You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1) your Contributions; (2) use of the App; (3) breach of these Terms and Conditions; (4) any breach of your representations and warranties set forth in these Terms and Conditions; (5) your violation of the rights of a third party, including but not limited to intellectual property rights; or (6) any overt harmful act toward any other user of the App with whom you connected via the App.</p>
                        <p>Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left">USER DATA</h5>
                        <p>We will maintain certain data that you transmit to the App for the purpose of managing the App, as well as data relating to your use of the App. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the App.</p>
                        <p>You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left">MISCELLANEOUS</h5>
                        <p>These Terms and Conditions and any policies or operating rules posted by us on the App constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Terms and Conditions shall not operate as a waiver of such right or provision.</p>
                        <p>These Terms and Conditions operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control.</p>
                        <p>If any provision or part of a provision of these Terms and Conditions is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms and Conditions and does not affect the validity and enforceability of any remaining provisions.</p>
                        <p>There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms and Conditions or use of the App. You agree that these Terms and Conditions will not be construed against us by virtue of having drafted them.</p>
                        <p>You hereby waive any and all defenses you may have based on the electronic form of these Terms and Conditions and the lack of signing by the parties hereto to execute these Terms and Conditions.</p>
                    </div>

                    <div className="mt-5">
                        <h5 className="text-left">CONTACT US</h5>
                        <p>In order to resolve a complaint regarding the App or to receive further information regarding use of the App, please contact us at:</p>
                        <p>Klean and shine sdn bhd <br /> Lot 53, Jln utas 15/7 section 15,<br /> 40000 Shah Alam</p>
                        <p>0129278893</p>
                        <p>Estherliow88@gmail.com</p>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default TermsConditions;