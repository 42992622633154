import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import Loading from "../components/Loading";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Tabs, Tab } from 'react-bootstrap';

const mySwal = withReactContent(Swal);

class RewardHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
        };
    }

    componentDidMount() {
        if (!this.props.admin) this.props.history.push("/admin/dashboard");
        else {
            axios
                .post("/api/admin/setting/reward/history", { operatorId: this.props.admin.operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        data: res.data,
                        loading: false
                    });
                })
                .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
        }
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    render() {
        let allTableData = {
            columns: [
                {
                    label: "User Name",
                    field: "name",
                    sort: "asc"
                },
                {
                    label: "Phone Number",
                    field: "number",
                    sort: "asc"
                },
                {
                    label: "Member ID",
                    field: "memberId",
                    sort: "asc"
                },
                {
                    label: "Reward Type",
                    field: "rewardType",
                    sort: "asc"
                },
                {
                    label: "Voucher ID",
                    field: "voucherId",
                    sort: "asc"
                },
                {
                    label: "Voucher Type",
                    field: "voucherType",
                    sort: "asc"
                },
                {
                    label: "Voucher Amount",
                    field: "voucherAmount",
                    sort: "asc"
                },
                {
                    label: "Washer Stamp",
                    field: "washerStamp",
                    sort: "asc"
                },
                {
                    label: "Dryer Stamp",
                    field: "dryerStamp",
                    sort: "asc"
                },
            ],
            rows: []
        };
        let voucherTableData = {
            columns: [
                {
                    label: "User Name",
                    field: "name",
                    sort: "asc"
                },
                {
                    label: "Phone Number",
                    field: "number",
                    sort: "asc"
                },
                {
                    label: "Member ID",
                    field: "memberId",
                    sort: "asc"
                },
                {
                    label: "Reward Type",
                    field: "rewardType",
                    sort: "asc"
                },
                {
                    label: "Voucher ID",
                    field: "voucherId",
                    sort: "asc"
                },
                {
                    label: "Voucher Type",
                    field: "voucherType",
                    sort: "asc"
                },
                {
                    label: "Voucher Amount",
                    field: "voucherAmount",
                    sort: "asc"
                },
            ],
            rows: []
        };
        let stampTableData = {
            columns: [
                {
                    label: "User Name",
                    field: "name",
                    sort: "asc"
                },
                {
                    label: "Phone Number",
                    field: "number",
                    sort: "asc"
                },
                {
                    label: "Member ID",
                    field: "memberId",
                    sort: "asc"
                },
                {
                    label: "Reward Type",
                    field: "rewardType",
                    sort: "asc"
                },
                {
                    label: "Washer Stamp",
                    field: "washerStamp",
                    sort: "asc"
                },
                {
                    label: "Dryer Stamp",
                    field: "dryerStamp",
                    sort: "asc"
                },
            ],
            rows: []
        };
        let tokenTableData = {
            columns: [
                {
                    label: "User Name",
                    field: "name",
                    sort: "asc"
                },
                {
                    label: "Phone Number",
                    field: "number",
                    sort: "asc"
                },
                {
                    label: "Member ID",
                    field: "memberId",
                    sort: "asc"
                },
                {
                    label: "Reward Type",
                    field: "rewardType",
                    sort: "asc"
                },
                {
                    label: "e-Token Amount",
                    field: "tokenAmount",
                    sort: "asc"
                },
            ],
            rows: []
        };

        if (this.props.admin?.token) {
            allTableData.columns.push(
                {
                    label: "e-Token Amount",
                    field: "tokenAmount",
                    sort: "asc"
                },
            );
        }

        if (this.state.data.length) {
            const voucherData = this.state.data.filter(data => data.rewardType === "voucher");
            const stampData = this.state.data.filter(data => data.rewardType === "stamp");
            const tokenData = this.state.data.filter(data => data.rewardType === "token");

            for (let i = 0; i < this.state.data.length; i++) {
                const dataObject = {
                    name: this.state.data[i].user.name,
                    number: this.state.data[i].user.number,
                    memberId: this.state.data[i].user.memberId,
                    rewardType: this.state.data[i].rewardType,
                    voucherId: this.state.data[i].voucher ? this.state.data[i].voucher.voucherId : "-",
                    voucherType: this.state.data[i].voucher ? this.state.data[i].voucher.type : "-",
                    voucherAmount: this.state.data[i].voucher ? this.state.data[i].voucher.amount : "-",
                    washerStamp: this.state.data[i].stamp ? this.state.data[i].stamp.washer : "-",
                    dryerStamp: this.state.data[i].stamp ? this.state.data[i].stamp.dryer : "-",
                    tokenAmount: this.state.data[i].token ? this.state.data[i].token : "-",
                };
                allTableData.rows.push(dataObject);
            }

            for (let i = 0; i < voucherData.length; i++) {
                const dataObject = {
                    name: voucherData[i].user.name,
                    number: voucherData[i].user.number,
                    memberId: voucherData[i].user.memberId,
                    rewardType: voucherData[i].rewardType,
                    voucherId: voucherData[i].voucher.voucherId,
                    voucherType: voucherData[i].voucher.type,
                    voucherAmount: voucherData[i].voucher.amount,
                };
                voucherTableData.rows.push(dataObject);
            }

            for (let i = 0; i < stampData.length; i++) {
                const dataObject = {
                    name: stampData[i].user.name,
                    number: stampData[i].user.number,
                    memberId: stampData[i].user.memberId,
                    rewardType: stampData[i].rewardType,
                    washerStamp: stampData[i].stamp.washer,
                    dryerStamp: stampData[i].stamp.dryer,
                };
                stampTableData.rows.push(dataObject);
            }

            for (let i = 0; i < tokenData.length; i++) {
                const dataObject = {
                    name: tokenData[i].user.name,
                    number: tokenData[i].user.number,
                    memberId: tokenData[i].user.memberId,
                    rewardType: tokenData[i].rewardType,
                    tokenAmount: tokenData[i].token,
                };
                tokenTableData.rows.push(dataObject);
            }
        }

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between">
                                <h5 className="card-title">Reward History</h5>
                            </div>

                            <div className="card-body text-center">{this.state.loading ? <Loading /> : (
                                <Tabs defaultActiveKey="all">
                                    <Tab eventKey="all" title="All">
                                        <MDBDataTable data={allTableData} noBottomColumns striped hover responsive bordered />
                                    </Tab>
                                    <Tab eventKey="voucher" title="Voucher">
                                        <MDBDataTable data={voucherTableData} noBottomColumns striped hover responsive bordered />
                                    </Tab>
                                    <Tab eventKey="stamp" title="Stamp">
                                        <MDBDataTable data={stampTableData} noBottomColumns striped hover responsive bordered />
                                    </Tab>
                                    {
                                        this.props.admin.token ? (
                                            <Tab eventKey="token" title="Token">
                                                <MDBDataTable data={tokenTableData} noBottomColumns striped hover responsive bordered />
                                            </Tab>
                                        ) : null
                                    }
                                </Tabs>
                            )}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        admin: state.admin.admin,
        token: state.admin.token
    };
};

export default connect(mapStateToProps, null)(RewardHistory);
