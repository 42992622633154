import { Switch, Route, Redirect } from "react-router-dom";
import UpdateOutletPage from "./UpdateOutletPage";
import UpdateAllOutletPage from "./UpdateAllOutletPage";
import UpdateOperatorPage from "./UpdateOperatorPage";
import AddOperatorPage from "./AddOperatorPage";
import AddOutletPage from "./AddOutletPage";
import DisableOperatorPage from "./DisableOperatorPage";
import AddDistributorPage from "./AddDistributorPage";

const DevDashboard = () => {
	return (
		<div>
			<Switch>
				<Route exact path="/dev/addDistributor" component={AddDistributorPage} />
				<Route exact path="/dev/addOperator" component={AddOperatorPage} />
                <Route exact path="/dev/addOutlet" component={AddOutletPage} />
				<Route exact path="/dev/updateOutlet" component={UpdateOutletPage} />
				<Route exact path="/dev/updateAllOutlet" component={UpdateAllOutletPage} />
				<Route exact path="/dev/updateOperator" component={UpdateOperatorPage} />
				<Route exact path="/dev/disableOperator" component={DisableOperatorPage} />
				<Redirect to="/dev/addDistributor" />
			</Switch>
		</div>
	);
};

export default DevDashboard;