import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Topbar from './components/Topbar';
import Sidebar from "./components/Sidebar";
import Loading from '../../admin/components/Loading';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MDBDataTable } from "mdbreact";

const mySwal = withReactContent(Swal);

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            toggled: false,
            adminList: []
        };
    };

    componentDidMount() {
        axios
            .post("/api/distributor/getAdmin", { distributorId: this.props.distributor.distributorId })
            .then(res => {
                this.setState({
                    ...this.state,
                    adminList: res.data.admin,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                mySwal.fire("Error", err.response.data.error, "error");
            });
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    render() {

        let tableData = {
            columns: [
                {
                    label: "Username",
                    field: "username",
                    sort: "asc"
                },
                {
                    label: "Total Users Registered",
                    field: "users",
                    sort: "asc"
                },
                {
                    label: "Total Active Outlets",
                    field: "outlets",
                    sort: "asc"
                },
                {
                    label: "Total Vouchers Given",
                    field: "vouchers",
                    sort: "asc"
                },
                {
                    label: "Total Transaction Counts",
                    field: "transaction",
                    sort: "asc"
                },
                {
                    label: "Total User Stamps Collected",
                    field: "stamps",
                    sort: "asc"
                },
            ],
            rows: []
        };

        if (this.state.adminList.length) {
            for (let i = 0; i < this.state.adminList.length; i++) {
                const dataObject = {
                    username: this.state.adminList[i].username,
                    users: this.state.adminList[i].userCount,
                    outlets: this.state.adminList[i].outletCount,
                    vouchers: this.state.adminList[i].outletVouchers,
                    transaction: this.state.adminList[i].transactionCount,
                    stamps: this.state.adminList[i].stampCount
                };
                tableData.rows.push(dataObject);
            }
        }

        if (this.state.loading) {
            return <Loading />
        }
        else {
            return (
                <div id="admin-dashboard" className="admin-page-container">
                    <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />

                    <div className="admin-page">
                        <Topbar handleToggle={this.handleToggle} />

                        <div className="admin-content">
                            <div className="text-center">
                                <h3>Welcome</h3>
                            </div>

                            <div className="mt-5">
                                <div className="card">
                                    <div className="card-header d-flex justify-content-between">
                                        <h5 className="card-title">Dashboard</h5>
                                    </div>

                                    <div className="card-body text-center">
                                        <MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };
};

const mapStateToProps = state => {
    return {
        distributor: state.distributor.distributor
    };
};

export default connect(mapStateToProps, null)(Dashboard);