import { Switch, Route, Redirect } from "react-router-dom";
import Dashboard from "./Dashboard";
import StorePerformace from "./StorePerformace";
import UserPerformance from "./UserPerformance";
import VoucherRedemption from "./VoucherRedemption";
import UserDetails from "./UserDetails";
import TokenReport from "./TokenReport";
import TokenDetail from "./TokenDetail";
import SmsReport from "./SmsReport";

const DistributorDashboard = () => {
	return (
		<div>
			<Switch>
				<Route exact path="/distributor/dashboard" component={Dashboard} />
				<Route exact path="/distributor/outlet/performance" component={StorePerformace} />
				<Route exact path="/distributor/user/performance" component={UserPerformance} />
				<Route exact path="/distributor/voucher/redemption" component={VoucherRedemption} />
				<Route exact path="/distributor/token/report" component={TokenReport} />
				<Route exact path="/distributor/token/report/detail" component={TokenDetail} />
				<Route exact path="/distributor/user/details" component={UserDetails} />
				<Route exact path="/distributor/sms/report" component={SmsReport} />
				<Redirect to="/distributor/dashboard" />
			</Switch>
		</div>
	);
};

export default DistributorDashboard;