import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { MDBDataTable } from "mdbreact";

class DisableOperator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
        };
    };

    componentDidMount() {
        if (this.props.dev.isAuthenticated) {
            axios
                .get("/api/dev/get/updateAdmin", { headers: { "Content-Type": "application/json", "auth-token": this.props.dev.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        data: res.data.data,
                        loading: false
                    });
                })
                .catch(err => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    alert(err.response.data.error);
                });

        }
    }

    handleChange = e => {
        this.setState({
            ...this.state,
            loading: true
        });

        const selectedOperator = this.state.data.filter(operator => operator.username === e.target.name)[0];

        axios.post('/api/dev/disableAdmin', { operatorId: selectedOperator.operatorId, disabled: !selectedOperator.disabled }, { headers: { "Content-Type": "application/json", "auth-token": this.props.dev.token } })
            .then(res => {
                this.setState({
                    ...this.state,
                    loading: false
                });

                axios
                    .get("/api/dev/get/updateAdmin", { headers: { "Content-Type": "application/json", "auth-token": this.props.dev.token } })
                    .then(res => {
                        this.setState({
                            ...this.state,
                            data: res.data.data,
                            loading: false
                        });
                    })
                    .catch(err => {
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                        alert(err.response.data.error);
                    });

            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                alert(err.response.data.error);
            });
    };

    render() {
        let tableData = {
            columns: [
                {
                    label: "Username",
                    field: "username",
                    sort: "asc"
                },
                {
                    label: "Disable",
                    field: "action"
                }
            ],
            rows: []
        };

        if (this.state.data.length) {
            for (let i = 0; i < this.state.data.length; i++) {
                const dataObject = {
                    username: this.state.data[i].username,
                    action: (
                        <div>
                            <div className="disable-toggle-switch">
                                <input type="checkbox" className="disable-checkbox" name={this.state.data[i].username} id={this.state.data[i].username} checked={this.state.data[i].disabled} value={this.state.data[i].disabled} onChange={this.handleChange} />
                                <label className="disable-label" htmlFor={this.state.data[i].username}>
                                    <span className="disable-inner" />
                                    <span className="disable-switch" />
                                </label>
                            </div>
                        </div>
                    )
                };
                tableData.rows.push(dataObject);
            }
        }

        if (this.state.loading) {
            return (
                <div className="text-center pt-5">
                    <h1>Loading ...</h1>
                </div>
            )
        } else {
            return (
                <div className="pt-5 px-5">

                    <div className="text-center">
                        <h1>Disable Operator</h1>
                    </div>

                    <div>
                        <MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered />
                    </div>

                </div>
            );
        }
    };
};

const mapStateToProps = state => {
    return {
        dev: state.dev
    };
};

export default connect(mapStateToProps, null)(DisableOperator);