import {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router";
import Sidebar from '../utilities/Sidebar';
import NewsSlider from "../main/dashboard/NewsSlider";

const News = () => {
    const [toggled , setToggled] = useState(false);
    const history = useHistory();

    const { t } = useTranslation();

    return (
        <div id="news-page">
            <Sidebar toggled={toggled} setToggled={setToggled} />
            <div id="user-header">
                <h5><i onClick={e => setToggled(true)} className="fas fa-bars me-3"></i> <strong>{t("Latest News")}</strong></h5>
            </div>

            <div id="user-body">
                <NewsSlider page="news" history={history}/>
            </div>
        </div>
    );
};

export default News;