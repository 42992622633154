// ----- to control all the dynamics components ----- //
// some function might be same, but I kept it separated so it can be changed next time
import logo1 from "./assets/images/logo/logo1.png";
import laundroLogo1 from "./assets/images/logo/laundroLogo1.png";
import mainLogo from "./assets/images/logo/mainLogo.png";
import laundroLogo from "./assets/images/logo/laundroLogo.png";
import washStudioLogo from "./assets/images/logo/washStudioLogo.png";
import washStudioLogo1 from "./assets/images/logo/washStudioLogo1.png";
import bubblesLogo from "./assets/images/logo/bubblesLogo.png";
import bubblesLogo1 from "./assets/images/logo/bubblesLogo1.png";

import laundroStep1 from "./assets/images/faq/Step1.png";
import laundroStep2 from "./assets/images/faq/Step2-1.png";
import laundroStep3 from "./assets/images/faq/Step2-2.png";
import laundroStep4 from "./assets/images/faq/Step3.png";
import laundroStep5 from "./assets/images/faq/Step4.png";
import laundroStep6 from "./assets/images/faq/Step5.png";
import cuciStep1 from "./assets/images/faq/CuciStep1.png";
import cuciStep2 from "./assets/images/faq/CuciStep2-1.png";
import cuciStep3 from "./assets/images/faq/CuciStep2-2.png";
import cuciStep4 from "./assets/images/faq/CuciStep3.png";
import cuciStep5 from "./assets/images/faq/CuciStep4.png";
import cuciStep6 from "./assets/images/faq/CuciStep5.png";
import bubblesStep1 from "./assets/images/faq/BubblesStep1.png";
import washStudioStep1 from "./assets/images/faq/WashStudioStep1.png";
import reloadFaq1 from "./assets/images/faq/reloadFaq1.png";
import reloadFaq2 from "./assets/images/faq/reloadFaq2.png";
import reloadFaq3 from "./assets/images/faq/reloadFaq3.png";
import cuciReloadFaq1 from "./assets/images/faq/cuciReloadFaq1.png";
import cuciReloadFaq2 from "./assets/images/faq/cuciReloadFaq2.png";
import cuciReloadFaq3 from "./assets/images/faq/cuciReloadFaq3.png";
import noVoucher from "./assets/images/faq/noVoucher.png";
import cuciNoVoucher from "./assets/images/faq/cuciNoVoucher.png";
import voucher1 from "./assets/images/faq/voucher1.png";
import voucher2 from "./assets/images/faq/voucher2.png";
import voucher3 from "./assets/images/faq/voucher3.png";
import cuciVoucher1 from "./assets/images/faq/cuciVoucher1.png";
import cuciVoucher2 from "./assets/images/faq/cuciVoucher2.png";
import cuciVoucher3 from "./assets/images/faq/cuciVoucher3.png";

const laundroFaq = [laundroStep1, laundroStep2, laundroStep3, laundroStep4, laundroStep5, laundroStep6, reloadFaq1, reloadFaq2, reloadFaq3, noVoucher, voucher1, voucher2, voucher3];
const cuciFaq = [cuciStep1, cuciStep2, cuciStep3, cuciStep4, cuciStep5, cuciStep6, cuciReloadFaq1, cuciReloadFaq2, cuciReloadFaq3, cuciNoVoucher, cuciVoucher1, cuciVoucher2, cuciVoucher3];
const bubblesFaq = [bubblesStep1, laundroStep2, laundroStep3, laundroStep4, laundroStep5, laundroStep6, reloadFaq1, reloadFaq2, reloadFaq3, noVoucher, voucher1, voucher2, voucher3];
const washStudioFaq = [washStudioStep1, laundroStep2, laundroStep3, laundroStep4, laundroStep5, laundroStep6, reloadFaq1, reloadFaq2, reloadFaq3, noVoucher, voucher1, voucher2, voucher3];

// to avoid typo, assign to variable
const STORETYPE = process.env.REACT_APP_STORETYPE;
const cuci = "cuci";
const laundro = "laundro";
const washStudio = "washStudio";
const bubbles = "bubbles";

// admin sidebar
export const logo1Controller = themeLogo => {
	if (STORETYPE === cuci) return logo1;
	else {
		if (themeLogo === "laundroLogo") {
			if (STORETYPE === laundro) return laundroLogo1;
			else if (STORETYPE === washStudio) return washStudioLogo1;
			else if (STORETYPE === bubbles) return bubblesLogo1;
			else return laundroLogo1;
		} else return themeLogo;
	}
};

// admin login page, user landing page, user sidebar
export const logoController = themeLogo => {
	if (STORETYPE === cuci) return mainLogo;
	else {
		if (themeLogo === "laundroLogo") {
			if (STORETYPE === laundro) return laundroLogo;
			else if (STORETYPE === washStudio) return washStudioLogo;
			else if (STORETYPE === bubbles) return bubblesLogo;
			else return laundroLogo;
		} else return themeLogo;
	}
};

// user navigator classNames
export const userNavigatorClassNames = (nav, val) => {
	if (STORETYPE === cuci) {
		if (val === nav) return "nav-tab waves-effect waves-yellow selected-nav";
		else return "nav-tab waves-effect waves-yellow";
	} else if (STORETYPE === laundro || STORETYPE === washStudio || STORETYPE === bubbles) {
		if (val === nav) return "nav-tab waves-effect selected-nav";
		else return "nav-tab waves-effect";
	} else {
		return "";
	}
};

// user landing page small title
export const smallTitle = () => {
	if (STORETYPE === laundro) return "Laundro Loyalty";
	else if (STORETYPE === washStudio) return "MyWashStudio Loyalty";
	else if (STORETYPE === bubbles) return "BubblesPulse Loyalty";
	else return null;
};

// distributor redirect laundro url
export const distributorRedirectUrl = () => {
	if (STORETYPE === washStudio) return `https://mywashstudio.com/franchisorYearlyOverview`;
	else if (STORETYPE === bubbles) return `https://bubblespulse.com.my/franchisorYearlyOverview`;
	else if (STORETYPE === cuci) return `https://laundro.cuciexpress.asia/franchisorYearlyOverview`;
	else return `https://thelaundro.com/franchisorYearlyOverview`;
};

// admin redirect laundro url
export const laundroRedirectUrl = () => {
	if (STORETYPE === washStudio) return `https://mywashstudio.com/mainDashboard`;
	else if (STORETYPE === bubbles) return `https://bubblespulse.com.my/mainDashboard`;
	else if (STORETYPE === cuci) return `https://laundro.cuciexpress.asia/mainDashboard`;
	else return `https://thelaundro.com/mainDailyDashboard`;
};

// admin redirect laundro text
export const laundroText = () => {
	if (STORETYPE === washStudio) return "MyWashStudio";
	else if (STORETYPE === bubbles) return "BubblesPulse";
	else if (STORETYPE === cuci) return "Cuci Express";
	else return "Laundro";
};

// default faq images
export const faqImage = () => {
	if (STORETYPE === washStudio) return washStudioFaq;
	else if (STORETYPE === bubbles) return bubblesFaq;
	else if (STORETYPE === cuci) return cuciFaq;
	else return laundroFaq;
};

export const navCameraClassName = process.env.REACT_APP_STORETYPE === "cuci" ? "waves-effect waves-orange" : "waves-effect waves-blue";
