import { MDBDataTable } from "mdbreact";

const VoucherType = ({ voucherData }) => {

    let data = {
        columns: [
            {
                label: "Voucher Type",
                field: "voucherType",
                sort: "asc"
            },
            {
                label: "Used",
                field: "used",
                sort: "asc"
            },
            {
                label: "Available",
                field: "available",
                sort: "asc"
            },
            {
                label: "Expired",
                field: "expired",
                sort: "asc"
            }
        ],
        rows: []
    };

    let vouchers = [];
    for (let i = 0; i < voucherData.length; i++) {
        if (!vouchers.includes(voucherData[i].from)) {
            vouchers.push(voucherData[i].from);
        }
    }

    for (let i = 0; i < vouchers.length; i++) {
        const dataObject = {
            voucherType: vouchers[i],
            used: voucherData.filter(voucher => voucher.from === vouchers[i] && voucher.available === "Used").length,
            available: voucherData.filter(voucher => voucher.from === vouchers[i] && voucher.available === "Available").length,
            expired: voucherData.filter(voucher => voucher.from === vouchers[i] && voucher.available === "Expired").length
        }
        data.rows.push(dataObject);
    }

    return (<MDBDataTable data={data} noBottomColumns striped hover responsive bordered />);
}

export default VoucherType;