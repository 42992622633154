import React, { Component } from 'react';
import axios from "axios";
import { connect } from "react-redux";
import Sidebar from '../components/Sidebar';
import Topbar from '../components/Topbar';
import Loading from "../components/Loading";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const mySwal = withReactContent(Swal);

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            loading: true,
            data: [],
        };
    };

    componentDidMount() {
        if (!this.props.admin.admin) this.props.history.push("/admin/dashboard");
        else {
            axios
                .post("/api/admin/setting/getNews", { operatorId: this.props.admin.admin.operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        data: res.data,
                        loading: false
                    });
                })
                .catch(err => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    mySwal.fire("Error", err.response.data.error, "error");
                });
        }
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    deleteNews = id => {
        mySwal.fire({
            title: "Confirmation",
            text: "Are you sure you want to delete news?",
            icon: "question",
            showCancelButton: true,
            cancelButtonText: "No",
            confirmButtonText: "Yes",
            cancelButtonColor: "#d33"
        }).then(click => {

            if (click.isConfirmed && click.value) {
                this.setState({
                    ...this.state,
                    loading: true
                });

                const operatorId = this.props.admin.admin.operatorId;
                const newsId = id;

                const sendThis = {
                    operatorId,
                    newsId
                };

                axios.post('/api/admin/setting/deleteNews', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                    .then(res => {
                        
                        axios
                            .post("/api/admin/setting/getNews", { operatorId: this.props.admin.admin.operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                            .then(res => {
                                this.setState({
                                    ...this.state,
                                    data: res.data,
                                    loading: false
                                });
                                mySwal.fire("Success", "News deleted successfully", "success");
                            })
                            .catch(err => {
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                                mySwal.fire("Error", err.response.data.error, "error");
                            });
                       
                    })
                    .catch(err => {
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                        mySwal.fire("Error", err.response.data.error, "error");
                    });
            }
        });
    }

    render() {

        let tableData = {
            columns: [
                {
                    label: "Title",
                    field: "title",
                    sort: "asc"
                },
                {
                    label: "Start Date",
                    field: "startDate",
                    sort: "asc"
                },
                {
                    label: "End Date",
                    field: "endDate",
                    sort: "asc"
                },
                {
                    label: "Action",
                    field: "action"
                }
            ],
            rows: []
        };

        if (this.state.data.length) {
            for (let i = 0; i < this.state.data.length; i++) {
                const dataObject = {
                    title: this.state.data[i].title,
                    startDate: this.state.data[i].startDate ? `${new Date(this.state.data[i].startDate).getDate()}/${new Date(this.state.data[i].startDate).getMonth()+1}/${new Date(this.state.data[i].startDate).getFullYear()}` : "-",
                    endDate: this.state.data[i].endDate ? `${new Date(this.state.data[i].endDate).getDate()}/${new Date(this.state.data[i].endDate).getMonth()+1}/${new Date(this.state.data[i].endDate).getFullYear()}` : "-",
                    action: (
                        <div>
                            <i className="fas fa-edit fa-lg" onClick={() => this.props.history.push(`/admin/news/edit/${this.state.data[i]._id}`)}></i>
                            <i className="fas fa-trash-alt fa-lg ms-3" onClick={() => this.deleteNews(this.state.data[i]._id)}></i>
                        </div>
                    )
                };
                tableData.rows.push(dataObject);

            }
        }

        const renderList = () => {

            if (this.state.loading) {
                return (
                    <Loading />
                );
            } else {
                if (!this.state.data.length) {
                    return (
                        <div className="text-center">
                            <h1> - NO NEWS - </h1>
                        </div>
                    );
                } else {
                    return (
                        <MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered />
                    );
                }
            }
        }

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />

                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />

                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between">
                                <h5 className="card-title">News</h5>

                                <Link className="btn btn-primary" to="/admin/news/add">
                                    Add News
                                </Link>
                            </div>

                            <div className="card-body">
                                {
                                    renderList()
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        admin: state.admin
    };
};

export default connect(mapStateToProps, null)(News);