import { distributorLogout } from "../../../../redux/actions/distributorActions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { distributorRedirectUrl, laundroText } from "../../../../dynamicController";

const Topbar = ({ handleToggle, logout }) => {
	const history = useHistory();
	return (
		<nav className="navbar">
			<div>
				<button onClick={() => handleToggle()}>
					<i style={{ fontSize: "20px" }} className="fas fa-bars"></i>
				</button>
			</div>

			<div className="dropdown">
				<button type="button" className="dropdown-toggle" id="dropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					<i style={{ fontSize: "40px" }} className="fas fa-user-circle"></i>
				</button>

				<div style={{ transform: "translate(-70px , 0px)" }} className="dropdown-menu m-0" aria-labelledby="dropdownMenu">
					<div className="dropdown-item">
						<button
							onClick={() => {
								logout();
								history.push("/distributor");
							}}
							className="btn"
						>
							Logout
						</button>
					</div>

					<a href={distributorRedirectUrl()} className="dropdown-item py-3" style={{ color: "black" }}>
						Back To {laundroText()}
					</a>
				</div>
			</div>
		</nav>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		logout: () => dispatch(distributorLogout())
	};
};

export default connect(null, mapDispatchToProps)(Topbar);
