import { Card, CardGroup } from 'react-bootstrap';

const SummaryData = ({ userData, voucherData, transactionData }) => {

    const registeredUsers = userData.length;
    const stampsTransaction = transactionData.filter(data => data.payment.paymentAmount >= 5);
    const stamps = stampsTransaction.length;
    const vouchers = voucherData.length;
    const transactions = transactionData.length;

    const today = new Date();
    const thisWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 14);

    const thisWeekUsers = userData.filter(data => new Date(data.createdAt) >= thisWeek && new Date(data.createdAt) <= today).length;
    const lastWeekUsers = userData.filter(data => new Date(data.createdAt) >= lastWeek && new Date(data.createdAt) <= thisWeek).length;
    let userPercentage = 0;
    if (thisWeekUsers === 0 && lastWeekUsers === 0) {
        userPercentage = 0;
    } else if (thisWeekUsers !== 0 && lastWeekUsers === 0) {
        userPercentage = 100;
    } else {
        userPercentage = ((thisWeekUsers - lastWeekUsers) / lastWeekUsers) * 100;
    }

    const thisWeekStamps = stampsTransaction.filter(data => new Date(data.createdAt) >= thisWeek && new Date(data.createdAt) <= today).length;
    const lastWeekStamps = stampsTransaction.filter(data => new Date(data.createdAt) >= lastWeek && new Date(data.createdAt) <= thisWeek).length;
    let stampPercentage = 0;
    if (thisWeekStamps === 0 && lastWeekStamps === 0) {
        stampPercentage = 0;
    } else if (thisWeekStamps !== 0 && lastWeekStamps === 0) {
        stampPercentage = 100;
    } else {
        stampPercentage = ((thisWeekStamps - lastWeekStamps) / lastWeekStamps) * 100;
    }

    const thisWeekVouchers = voucherData.filter(data => new Date(data.createdAt) >= thisWeek && new Date(data.createdAt) <= today).length;
    const lastWeekVouchers = voucherData.filter(data => new Date(data.createdAt) >= lastWeek && new Date(data.createdAt) <= thisWeek).length;
    let voucherPercentage = 0;
    if (thisWeekVouchers === 0 && lastWeekVouchers === 0) {
        voucherPercentage = 0;
    } else if (thisWeekVouchers !== 0 && lastWeekVouchers === 0) {
        voucherPercentage = 100;
    } else {
        voucherPercentage = ((thisWeekVouchers - lastWeekVouchers) / lastWeekVouchers) * 100;
    }

    const thisWeekTransactions = transactionData.filter(data => new Date(data.createdAt) >= thisWeek && new Date(data.createdAt) <= today).length;
    const lastWeekTransactions = transactionData.filter(data => new Date(data.createdAt) >= lastWeek && new Date(data.createdAt) <= thisWeek).length;
    let transactionPercentage = 0;
    if (thisWeekTransactions === 0 && lastWeekTransactions === 0) {
        transactionPercentage = 0;
    } else if (thisWeekTransactions !== 0 && lastWeekTransactions === 0) {
        transactionPercentage = 100;
    } else {
        transactionPercentage = ((thisWeekTransactions - lastWeekTransactions) / lastWeekTransactions) * 100;
    }

    return (
        <div>
            <CardGroup>
                <Card style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <Card.Body>
                        <Card.Subtitle>Registered Users</Card.Subtitle>
                        <Card.Title style={{ paddingTop: "10px" }}>{registeredUsers}</Card.Title>
                        <p style={{ color: userPercentage >= 0 ? "green" : "red" }}><i className={userPercentage >= 0 ? "fas fa-arrow-up" : "fas fa-arrow-down"}></i> {Math.abs(userPercentage.toFixed(2))} %</p>
                        <div style={{ position: "absolute", top: "65px", right: "30px", color: "grey" }}><i className="fas fa-users fa-3x"></i></div>
                    </Card.Body>
                </Card>
                <Card style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <Card.Body>
                        <Card.Subtitle>Vouchers</Card.Subtitle>
                        <Card.Title style={{ paddingTop: "10px" }}>{vouchers}</Card.Title>
                        <p style={{ color: voucherPercentage >= 0 ? "green" : "red" }}><i className={voucherPercentage >= 0 ? "fas fa-arrow-up" : "fas fa-arrow-down"}></i> {Math.abs(voucherPercentage.toFixed(2))} %</p>
                        <div style={{ position: "absolute", top: "65px", right: "30px", color: "grey" }}><i className="fas fa-ticket-alt fa-3x"></i></div>
                    </Card.Body>
                </Card>
                <Card style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <Card.Body>
                        <Card.Subtitle>Stamps</Card.Subtitle>
                        <Card.Title style={{ paddingTop: "10px" }}>{stamps}</Card.Title>
                        <p style={{ color: stampPercentage >= 0 ? "green" : "red" }}><i className={stampPercentage >= 0 ? "fas fa-arrow-up" : "fas fa-arrow-down"}></i> {Math.abs(stampPercentage.toFixed(2))} %</p>
                        <div style={{ position: "absolute", top: "65px", right: "30px", color: "grey" }}><i className="fas fa-stamp fa-3x"></i></div>
                    </Card.Body>
                </Card>
                <Card style={{ marginLeft: "10px", marginRight: "10px" }}>
                    <Card.Body>
                        <Card.Subtitle>Transactions</Card.Subtitle>
                        <Card.Title style={{ paddingTop: "10px" }}>{transactions}</Card.Title>
                        <p style={{ color: transactionPercentage >= 0 ? "green" : "red" }}><i className={transactionPercentage >= 0 ? "fas fa-arrow-up" : "fas fa-arrow-down"}></i> {Math.abs(transactionPercentage.toFixed(2))} %</p>
                        <div style={{ position: "absolute", top: "65px", right: "30px", color: "grey" }}><i className="fas fa-coins fa-3x"></i></div>
                    </Card.Body>
                </Card>
            </CardGroup>
        </div>
    );
}

export default SummaryData;