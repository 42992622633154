import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import i18n from "i18next";
//import { InputGroup, Button, FormControl, Tooltip, OverlayTrigger } from 'react-bootstrap';

import { updateUser, changeLanguage } from '../../../redux/actions/userActions';
import SettingDetails from './setting/SettingDetails';

const mySwal = withReactContent(Swal);

class Setting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selected: "",
            name: "",
            email: "",
            // oldPassword : "",
            newPassword: "",
            language: "",
            //copy: false
        };
    };

    // copyMemberId = () => {
    //     navigator.clipboard.writeText(`https://${window.location.hostname}/welcome?operatorCode=${this.props.operatorId}&referralCode=${this.props.user.memberId}`);
    //     this.setState({ copy: true });
    //     setTimeout(() => { this.setState({ copy: false }); }, 1000);
    // };

    handleImage = e => {
        const file = e.target.files[0];

        const imageRegex = /image\//g;
        const jpgRegex = /.jpg/g;
        const pngRegex = /.png/g;
        const jpegRegex = /.jpeg/g;

        if (imageRegex.test(file.type)) {
            if (jpgRegex.test(file.name) || pngRegex.test(file.name) || jpegRegex.test(file.name)) {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("id", this.props.user._id);
                this.setState({ loading: true });
                axios.post('/api/user/setting/profilePicture', formData, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                    .then(res => {
                        this.setState({ loading: false });
                        this.props.updateUser({ type: "image", data: res.data.data });
                        mySwal.fire(i18n.t("Success"), i18n.t(res.data.message), "success");
                    })
                    .catch(err => {
                        this.setState({ loading: false });
                        mySwal.fire("Error", err.response.data.error, "error");
                    });

            } else {
                mySwal.fire(i18n.t("Info"), i18n.t("Please only use png, jpg or jpeg file extension type"), "info");
            }
        } else {
            mySwal.fire(i18n.t("Info"), i18n.t("Please only use image type file"), "info");
        }
    };

    handleBack = () => this.setState({ selected: "" });

    handleChange = e => this.setState({ ...this.state, [e.target.id]: e.target.value });

    handleSubmit = e => {
        e.preventDefault();
        const id = this.props.user._id;
        if (e.target.id === "name-form") {
            const { name } = this.state;
            // console.log({id , name});
            if (this.props.user.name === name) {
                this.setState({
                    ...this.state,
                    selected: "",
                    name: ""
                });
            } else {
                axios.post('/api/user/setting/userName', { id, name }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                    .then(res => {
                        this.setState({
                            ...this.state,
                            selected: "",
                            name: ""
                        });
                        this.props.updateUser({ type: "name", data: res.data.data });
                        // console.log(res.data);
                        mySwal.fire(i18n.t("Success"), i18n.t(res.data.message), "success");
                    })
                    .catch(err => {
                        this.setState({
                            ...this.state,
                            selected: "",
                            name: ""
                        });
                        mySwal.fire("Error", err.response.data.error, "error");
                    });
            }

        } else if (e.target.id === "email-form") {
            const { email } = this.state;
            // console.log({ id , email });
            if (this.props.user.email === email) {
                this.setState({
                    ...this.state,
                    selected: "",
                    email: ""
                });
            } else {
                axios.post('/api/user/setting/email', { id, email }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                    .then(res => {
                        this.setState({
                            ...this.state,
                            selected: "",
                            email: ""
                        });
                        this.props.updateUser({ type: "email", data: res.data.data });
                        mySwal.fire(i18n.t("Success"), i18n.t(res.data.message), "success");
                    })
                    .catch(err => {
                        mySwal.fire("Error", err.response.data.error, "error");
                    });
            }

        } else if (e.target.id === "password-form") {
            const { /*oldPassword , */newPassword } = this.state;
            if (newPassword.length < 6) {
                mySwal.fire(i18n.t("Info"), i18n.t("Password must be at least 6 characters"), "info");
            } else {
                // console.log({ id , oldPassword , newPassword });
                this.setState({
                    ...this.state,
                    loading: true
                });
                axios.post('/api/user/setting/password', { id, newPassword }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                    .then(res => {
                        // console.log(res.data);
                        this.setState({
                            ...this.state,
                            selected: "",
                            // oldPassword : "",
                            newPassword: "",
                            loading: false
                        });
                        mySwal.fire(i18n.t("Success"), i18n.t(res.data.message), "success");
                    })
                    .catch(err => {
                        this.setState({
                            ...this.state,
                            // oldPassword : "",
                            newPassword: "",
                            loading: false
                        });
                        mySwal.fire("Error", err.response.data.error, "error");
                    });
            }
        } else if (e.target.id === "language-form") {
            const { language } = this.state;

            if (this.props.language === language) {
                this.setState({
                    ...this.state,
                    selected: "",
                    language: ""
                });
            } else {
                this.props.changeLanguage(language);
                mySwal.fire(i18n.t("Success"), i18n.t("Language has updated successfully!"), "success");
            }
        }
    };

    displayLanguage = (language) => {
        if (language === "en") {
            return "English";
        } else if (language === "ms") {
            return "Malay";
        } else if (language === "zh") {
            return "Chinese";
        }
    }

    // share = () => {
    //     navigator.share({
    //         title: 'Referral Code Sharing',
    //         text: `Hi, you are invited to register an account with my referral code(${this.props.user.memberId}).`,
    //         url: `https://${window.location.hostname}/welcome?operatorCode=${this.props.operatorId}&referralCode=${this.props.user.memberId}`,
    //     })
    //         .then(() => console.log('Successful share'))
    //         .catch((error) => console.log('Error sharing', error));
    // }

    render() {
        if (this.props.user) {
            return (
                <div id="setting-page">
                    <div id="setting-main" className={this.state.selected ? "selected" : ""}>
                        <div id="user-header">
                            <h5><i onClick={e => this.props.history.push('/payment')} className="fas fa-chevron-left me-3"></i> <strong>{i18n.t("User Settings")}</strong></h5>
                        </div>

                        <div id="user-body">
                            <div id="edit-pp">
                                <div id="pp-container">
                                    <div style={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0, borderRadius: "50%", overflow: "hidden", display: "flex", alignContent: "center", textAlign: "center" }}>
                                        {
                                            this.state.loading
                                                ? (
                                                    <div className="m-auto">
                                                        <div className="spinner-border" style={{ color: "#ffd109" }} role="status">
                                                            <span className="sr-only">Loading ...</span>
                                                        </div>
                                                    </div>
                                                )
                                                : (
                                                    this.props.user.profilePicture
                                                        ? (<img src={this.props.user.profilePicture} alt="pp" className="img-fluid" />)
                                                        : (<i id="temp-pp" className="fas fa-user"></i>)
                                                )
                                        }
                                    </div>

                                    <input type="file" accept="image/*" id="profilePicture" name="profilePicture" onChange={this.handleImage} />

                                    <div id="faimage-container">
                                        <i className="fas fa-image"></i>
                                    </div>
                                </div>

                                <p style={{ margin: "25px 0 0 0", fontWeight: "bold" }}>{i18n.t("Profile Picture")}</p>
                            </div>

                            {/* {
                                this.props.user.referral ? (
                                    <div className="px-5">
                                        <h3 className="text-center">{i18n.t("Referral Code")}:</h3>
                                        <div style={{ display: "flex" }}>
                                            <InputGroup className="mb-3" >
                                                <FormControl
                                                    value={this.props.user.memberId}
                                                    readOnly
                                                    style={{
                                                        border: "1px solid #6c757d",
                                                        textAlign: "center",
                                                        borderTopLeftRadius: "25px",
                                                        borderBottomLeftRadius: "25px"
                                                    }}
                                                />
                                                <OverlayTrigger
                                                    placement="top"
                                                    show={this.state.copy}
                                                    overlay={
                                                        <Tooltip>
                                                            {i18n.t("Copied!")}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Button className="fas fa-copy" variant="outline-secondary" style={{ zIndex: "1", width: "43px", height: "43px", borderTopRightRadius: "25px", borderBottomRightRadius: "25px" }} onClick={() => this.copyMemberId()} />
                                                </OverlayTrigger>
                                            </InputGroup>
                                            <Button className="ms-2" variant="outline-secondary" style={{ zIndex: "1", width: "43px", height: "43px", borderRadius:"25px" }} onClick={() => this.share()}><i className="fas fa-share"></i></Button>
                                        </div>
                                    </div>
                                ) : null
                            } */}

                            <ul id="setting-list" className="list-group">
                                <li className="list-group-item" onClick={() => this.setState({ selected: "name", name: this.props.user.name })}>
                                    <div>
                                        <p className="m-0 font-weight-bold">{i18n.t("Name")}</p>
                                        <p className="m-0">{this.props.user.name}</p>
                                    </div>

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <i className="fas fa-chevron-right"></i>
                                    </div>
                                </li>

                                <li className="list-group-item" onClick={() => this.setState({ selected: "email", email: this.props.user.email })}>
                                    <div>
                                        <p className="m-0 font-weight-bold">{i18n.t("Email")}</p>
                                        <p className="m-0">{this.props.user.email}</p>
                                    </div>

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <i className="fas fa-chevron-right"></i>
                                    </div>
                                </li>

                                <li className="list-group-item" onClick={() => this.setState({ selected: "password" })}>
                                    <div>
                                        <p className="m-0 font-weight-bold">{i18n.t("Change Password")}</p>
                                    </div>

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <i className="fas fa-chevron-right"></i>
                                    </div>
                                </li>

                                {/* <li className="list-group-item" onClick={() => this.setState({ selected: "language", language: this.props.language })}>
                                    <div>
                                        <p className="m-0 font-weight-bold">{i18n.t("Change Language")}</p>
                                        <p className="m-0">{this.displayLanguage(this.props.language)}</p>
                                    </div>

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <i className="fas fa-chevron-right"></i>
                                    </div>
                                </li> */}
                            </ul>
                        </div>
                    </div>


                    <div id="setting-detail" className={this.state.selected ? "selected" : ""}>
                        <SettingDetails
                            selected={this.state.selected}
                            handleBack={this.handleBack}
                            handleChange={this.handleChange}
                            handleSubmit={this.handleSubmit}
                            name={this.state.name}
                            email={this.state.email}
                            // oldPassword={this.state.oldPassword}
                            newPassword={this.state.newPassword}
                            language={this.state.language}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            );

        } else {
            return <Redirect to="/payment" />
        }
    };
};

const mapStateToProps = state => {
    return {
        user: state.user.user,
        token: state.user.token,
        language: state.user.language,
        //operatorId: state.user.operatorId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateUser: data => dispatch(updateUser(data)),
        changeLanguage: language => dispatch(changeLanguage(language)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Setting);