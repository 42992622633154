import { DISTRIBUTOR_LOGIN, IS_LOADING, IS_LOADED, SHOW_ERROR, CLEAR_ERROR, REMOVE_TOKEN } from '../actions/type';

const initState = {
    isAuthenticated : false,
    isLoading: false,
    error: null,
    token: localStorage.getItem("laundry-distributor"),
    distributor: null
};

const distributorReducer = (state = initState, action) => {
    switch(action.type) {
        case IS_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case IS_LOADED:
            return {
                ...state,
                isLoading: false
            };
        case SHOW_ERROR:
            return {
                ...state,
                error: action.payload
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            };
        case DISTRIBUTOR_LOGIN:
            localStorage.setItem("laundry-distributor", action.payload.token);
            return {
                ...state,
                isAuthenticated: true,
                token: action.payload.token,
                distributor: action.payload.distributor
            };
        case REMOVE_TOKEN:
			localStorage.removeItem("laundry-distributor");
			return {
				...state,
				isAuthenticated: false,
                token: null,
                distributor: null
			};
        default:
            return state;
    }
};

export default distributorReducer;