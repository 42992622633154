import { DISTRIBUTOR_LOGIN, IS_LOADING, IS_LOADED, SHOW_ERROR, CLEAR_ERROR, REMOVE_TOKEN } from './type';
import axios from 'axios';

export const distributorLogin = data => dispatch => {
    dispatch({ type: IS_LOADING });
    axios.post('/api/distributor/loginDistributor', data)
        .then(res => {
            dispatch({ type: DISTRIBUTOR_LOGIN, payload: res.data.data });
            dispatch({ type: CLEAR_ERROR });
            dispatch({ type: IS_LOADED });
        })
        .catch(err => {
            dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
            dispatch({ type: IS_LOADED });
        });
};

export const checkToken = () => (dispatch, getState) => {
    if (getState().distributor.token) {
        axios.post('/api/distributor/checkTokenDistributor', { token: getState().distributor.token })
            .then(res => dispatch({ type: DISTRIBUTOR_LOGIN, payload: res.data.data }))
            .catch(err => {
                console.log(err);
                dispatch({ type: REMOVE_TOKEN });
            });
    }
};
export const distributorTokenLogin = token => dispatch => {
    axios
        .post("/api/distributor/tokenLogin", { token })
        .then(res => dispatch({ type: DISTRIBUTOR_LOGIN, payload: res.data.data }))
        .catch(err => {
            console.log(err);
            dispatch({ type: REMOVE_TOKEN });
        });
};

export const distributorLogout = () => {
    return {
        type: REMOVE_TOKEN
    };
};